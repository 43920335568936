import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Col, Row, Spin } from 'antd';
import { connect } from 'react-redux';
import './TsReservationDetailsView.scss';
import { tsReservationOverviewAction } from '../../actions/actionCreators/tsReservationActionCreators';
import {
  translateClassificator,
  translateTsFuelGenerationClassificator as tFuelGeneration,
  translateHydrogenFuelTypeClassificator,
  translateBooleanClassificator,
  translateHydrogenTechnologyTypeClassificator,
} from '../../utils/translateHelpers';
import { numberFormatter } from '../../utils/formaters';

export class TsReservationDetailsView extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    assignmentId: PropTypes.number.isRequired,
    hideModal: PropTypes.func.isRequired,
    fetchReservedItems: PropTypes.func.isRequired,
  };

  state = {
    reservedStatisticsData: null,
  };

  componentDidMount = () => {
    const { assignmentId, fetchReservedItems } = this.props;
    fetchReservedItems(assignmentId).then(res =>
      this.setState({ reservedStatisticsData: res })
    );
  };

  renderRow = (label, value) => (
    <div>
      <span>{label}: </span>
      <span>{value}</span>
    </div>
  );

  render() {
    const { t } = this.props;
    const { reservedStatisticsData } = this.state;

    if (reservedStatisticsData == null) return <Spin />;

    return (
      <form>
        {Object.values(reservedStatisticsData).map((ts, i) => {
          const isB = ts.type === 'B';
          const isH = ts.type === 'H';
          const isE = ts.type === 'E';
          const isS = ts.type === 'S';

          return (
            <div key={i}>
              <b>{i + 1}</b>
              <Row gutter={32}>
                <Col
                  span={24}
                  className="detail-container global-margin-bottom-10"
                >
                  {this.renderRow(
                    t('assignments.reserveDetails.type'),
                    t('assignments.reserveDetails.type' + ts.type)
                  )}
                  {isH &&
                    this.renderRow(
                      t('assignments.reserveDetails.hydrogenType'),
                      translateHydrogenFuelTypeClassificator(ts.biofuelType)
                    )}
                  {isH &&
                    this.renderRow(
                      t('assignments.reserveDetails.rfnbo'),
                      translateBooleanClassificator(ts.rfnbo + '')
                    )}
                  {this.renderRow(
                    t('assignments.reserveDetails.expirationDate'),
                    ts.expirationDate
                  )}
                  {this.renderRow(
                    t('assignments.reserveDetails.naturalAmountMj'),
                    numberFormatter(ts.naturalEnergyAmount)
                  )}
                  {this.renderRow(
                    t('assignments.reserveDetails.calculatedMultiplier'),
                    ts.calculatedMultiplier
                  )}
                  {this.renderRow(
                    t('assignments.reserveDetails.calculatedAmountMj'),
                    numberFormatter(ts.calculatedEnergyAmount)
                  )}
                  {!isE &&
                    !isH &&
                    this.renderRow(
                      t('assignments.reserveDetails.biofuelType'),
                      tFuelGeneration(ts.biofuelType)
                    )}
                  {!isH &&
                    this.renderRow(
                      t('assignments.reserveDetails.ghgCapacity'),
                      numberFormatter(ts.ghgCapacity)
                    )}
                </Col>
              </Row>
              <Row gutter={32}>
                <Col
                  span={24}
                  className="detail-container global-margin-bottom-10"
                >
                  {this.renderRow(
                    t('assignments.reserveDetails.consumptionPeriod'),
                    (!isS ? ts.consumptionMonth + '.' : '') + ts.consumptionYear
                  )}
                  {!isS &&
                    this.renderRow(
                      t('assignments.reserveDetails.consumptionProvider'),
                      ts.consumptionProvider
                    )}
                  {!isS &&
                    !isH &&
                    this.renderRow(
                      t(
                        'assignments.reserveDetails.consumptionProviderTransactionId'
                      ),
                      ts.consumptionProviderTransactionId
                    )}
                  {(isB || isH) &&
                    this.renderRow(
                      t('assignments.reserveDetails.productionPeriod'),
                      ts.productionPeriod
                    )}
                  {(isB || isH) &&
                    this.renderRow(
                      t('assignments.reserveDetails.productionUnitEicwCode'),
                      ts.productionUnitEicwCode
                    )}
                  {isB &&
                    this.renderRow(
                      t('assignments.reserveDetails.posNumber'),
                      ts.posNumber
                    )}
                </Col>
              </Row>
              {isB && (
                <Row gutter={32}>
                  <Col
                    span={24}
                    className="detail-container global-margin-bottom-10"
                  >
                    {this.renderRow(
                      t('assignments.reserveDetails.energyType'),
                      translateClassificator('energyType')(ts.energyType)
                    )}
                    {this.renderRow(
                      t('assignments.reserveDetails.biofuelComponentCn'),
                      ts.biofuelComponentCn
                    )}
                    {this.renderRow(
                      t('assignments.reserveDetails.sustainableBiofuel'),
                      translateClassificator('sustainableBiofuel')(
                        ts.sustainableBiofuel + ''
                      )
                    )}
                    {this.renderRow(
                      t('assignments.reserveDetails.fuelType'),
                      ts.translatedFuelType
                    )}
                    {this.renderRow(
                      t('assignments.reserveDetails.feedstock'),
                      translateClassificator('feedstock')(ts.feedstock)
                    )}
                    {this.renderRow(
                      t('assignments.reserveDetails.productionPathway'),
                      translateClassificator('productionPathway')(
                        ts.productionPathway
                      )
                    )}
                    {this.renderRow(
                      t('assignments.reserveDetails.lowerCalorificValueKg'),
                      ts.lowerCalorificValueKg
                    )}
                    {this.renderRow(
                      t('assignments.reserveDetails.landUseCategory'),
                      translateClassificator('landUseCategory')(
                        ts.landUseCategory
                      )
                    )}
                    {this.renderRow(
                      t('assignments.reserveDetails.landUseEmissions'),
                      ts.landUseEmissions
                    )}
                  </Col>
                </Row>
              )}
              {isH && (
                <Row gutter={32}>
                  <Col
                    span={24}
                    className="detail-container global-margin-bottom-10"
                  >
                    {this.renderRow(
                      t('assignments.reserveDetails.technologyType'),
                      translateHydrogenTechnologyTypeClassificator(
                        ts.technologyType
                      )
                    )}
                    {this.renderRow(
                      t('assignments.reserveDetails.lowerCalorificValueKg'),
                      ts.lowerCalorificValueKg
                    )}
                    {this.renderRow(
                      t('assignments.reserveDetails.higherCalorificValueKg'),
                      ts.higherCalorificValueKg
                    )}
                    {this.renderRow(
                      t('assignments.reserveDetails.ghgCapacity'),
                      numberFormatter(ts.ghgCapacity)
                    )}
                  </Col>
                </Row>
              )}
              <br />
            </div>
          );
        })}
      </form>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = (dispatch, ownProps) => ({
  fetchReservedItems: id => dispatch(tsReservationOverviewAction(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(
  TsReservationDetailsView
);
