import React from 'react';
import { Table } from 'antd';
import { isEmpty, isString } from 'lodash';
import { electricMeteringPointDataFields } from '../../constants/meteringPoint';
import { translateMonthClassificator } from '../../utils/translateHelpers';

const totalName = 'total';

const sumObjects = (base, adding) => {
  const result = { ...base };
  Object.keys(result)
    .filter(
      key =>
        key !== electricMeteringPointDataFields.MONTH &&
        key !== electricMeteringPointDataFields.AMOUNT_BY_MONTH
    )
    .forEach(key => (result[key] += adding[key] ? adding[key] : 0));
  return result;
};

const createDataWithSaldoRows = data => {
  const dataWithSaldoRows = [];
  data.forEach(row => {
    dataWithSaldoRows.push(row);
    const amountByMonth = Object.keys(
      row[electricMeteringPointDataFields.AMOUNT_BY_MONTH]
    );
    if (amountByMonth.length > 1) {
      amountByMonth.forEach(key => {
        dataWithSaldoRows.push({
          [electricMeteringPointDataFields.MONTH]: `${
            row[electricMeteringPointDataFields.MONTH]
          } - ${key}`,
          [electricMeteringPointDataFields.CONSUMED_AMOUNT_MWH]: `${translateMonthClassificator(
            key
          )} * ${row[electricMeteringPointDataFields.AMOUNT_BY_MONTH][
            key
          ].toFixed(6)}`,
          [electricMeteringPointDataFields.CURRENT_SALDO]: '',
          [electricMeteringPointDataFields.NATURAL_QUANTITY_MJ]: '',
          [electricMeteringPointDataFields.ACCOUNTING_QUANTITY_MJ]: '',
        });
      });
    }
  });
  return dataWithSaldoRows;
};

const calculateTotal = data => {
  if (isEmpty(data)) return [];

  const dataWithSaldoRows = createDataWithSaldoRows(data);

  let totalRow = {
    [electricMeteringPointDataFields.MONTH]: totalName,
    [electricMeteringPointDataFields.CONSUMED_AMOUNT_MWH]: 0,
    [electricMeteringPointDataFields.CURRENT_SALDO]: 0,
    [electricMeteringPointDataFields.NATURAL_QUANTITY_MJ]: 0,
    [electricMeteringPointDataFields.ACCOUNTING_QUANTITY_MJ]: 0,
  };

  totalRow = data.reduce(sumObjects, totalRow);

  dataWithSaldoRows.push(totalRow);

  return dataWithSaldoRows;
};

export function ElectricMeteringPointAgrDataTable({ t, data }) {
  const columns = [
    {
      dataIndex: electricMeteringPointDataFields.MONTH,
      title: t('data.month'),
      render: value =>
        value !== totalName
          ? translateMonthClassificator(value)
          : t(`data.${totalName}`),
    },
    {
      dataIndex: electricMeteringPointDataFields.CONSUMED_AMOUNT_MWH,
      title: t('data.amountMWh'),
      render: value => (isString(value) ? value : (value || 0).toFixed(3)),
    },
    {
      dataIndex: electricMeteringPointDataFields.CURRENT_SALDO,
      title: t('data.correctionMJ'),
      render: value => (isString(value) ? value : (value || 0).toFixed(3)),
    },
    {
      dataIndex: electricMeteringPointDataFields.NATURAL_QUANTITY_MJ,
      title: t('data.naturalQuantityMJ'),
      render: value => (isString(value) ? value : (value || 0).toFixed(1)),
    },
    {
      dataIndex: electricMeteringPointDataFields.ACCOUNTING_QUANTITY_MJ,
      title: t('data.accountingQuantityMJ'),
      render: value => (isString(value) ? value : (value || 0).toFixed(1)),
    },
  ];

  return (
    <>
      <Table
        columns={columns}
        rowKey={electricMeteringPointDataFields.MONTH}
        dataSource={calculateTotal(data)}
        pagination={false}
      />
      <span className="global-margin-top-10" style={{ display: 'block' }}>
        *{t('data.correction')}
      </span>
    </>
  );
}
