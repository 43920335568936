import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getLoadingSelector } from '../../utils/asyncHelpers';
import * as selectors from '../../reducers/lockingGOSCertificateReducer';
import * as actions from '../../actions/actionCreators/gosCertificateActionCreators';
import PropTypes from 'prop-types';
import PropTypesImmutable from 'react-immutable-proptypes';
import { Spin } from 'antd';
import { TradingAccountOverviewDetailsTable } from '../../components';
import { YearSelect } from '../../atoms';
import { getSelectedReportRowsData } from '../../reducers/lockingGOSCertificateReducer';
import {
  TYPE_B,
  TYPE_E,
  TYPE_H,
  TYPE_KHG,
  TYPE_S,
} from '../../constants/accountGOS';
import classifiersStore from '../../utils/classifiersStore';

import classificatorTypes from '../../constants/classificators';
import _ from 'lodash';

class TradingAccountLock extends Component {
  static propTypes = {
    isLoading: PropTypes.bool.isRequired,
    selectReceiver: PropTypes.func.isRequired,
    tradingAccountLockAction: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    groupedGOSCertificates: PropTypesImmutable.list.isRequired,
    type: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);
    const currentDate = new Date();
    let selectedYear;
    const date = classifiersStore
      .getClassifierByType(classificatorTypes.automaticGosExpirationDate)[0]
      .properties[0].value.split('.');

    if (
      currentDate.getMonth() + 1 > Number(date[1]) ||
      (currentDate.getMonth() + 1 === Number(date[1]) &&
        currentDate.getDate() > Number(date[0]))
    ) {
      selectedYear = currentDate.getFullYear();
    } else {
      selectedYear = currentDate.getFullYear() - 1;
    }
    this.state = {
      year: selectedYear,
      selectedYear: '',
      isB: props.type === TYPE_B,
      isE: props.type === TYPE_E,
      isS: props.type === TYPE_S,
      isKHG: props.type === TYPE_KHG,
      isH: props.type === TYPE_H,
    };
  }

  render() {
    const {
      isLoading,
      t,
      type,
      groupedGOSCertificates,
      tradingAccountLockAction,
    } = this.props;

    const { selectedYear, year, isB, isE, isS, isKHG, isH } = this.state;

    if (!isLoading && groupedGOSCertificates.isEmpty()) {
      return t('emptyLocking');
    }

    return (
      <Spin spinning={isLoading}>
        {(isB || isE) && <h2>{t('defineBioAdditionPeriod')}</h2>}
        {isH && <h2>{t('defineLockPeriod')}</h2>}
        {(isB || isE || isH) && (
          <YearSelect
            range={_.range(year, year + 1)}
            onChange={value => {
              this.setState({
                selectedYear: value,
              });
            }}
            value={selectedYear}
          />
        )}
        {(typeof selectedYear === 'number' || isS || isKHG) && (
          <React.Fragment>
            <h2 className="global-margin-top-24">{t(`define${type}Amount`)}</h2>
            <TradingAccountOverviewDetailsTable
              t={t}
              type={type}
              popconfirmTitle={t('popconfirmTitleLock')}
              okText={t('popconfirmButtonYes')}
              cancelText={t('popconfirmButtonNo')}
              dataList={groupedGOSCertificates.toJS()}
              isLocking
              performAction={rows =>
                tradingAccountLockAction({
                  period: selectedYear,
                  rows,
                })
              }
            />
          </React.Fragment>
        )}
      </Spin>
    );
  }
}

const getLoading = getLoadingSelector(
  getSelectedReportRowsData,
  selectors.getLocking
);

const mapStateToProps = state => ({
  isLoading: getLoading(state),
  groupedGOSCertificates: getSelectedReportRowsData(state),
});

const mapDispatchToProps = {
  tradingAccountLockAction: actions.tradingAccountLockAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(TradingAccountLock);
