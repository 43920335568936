import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { AccountGOSAuctionsSearchTable } from '../../components';
import { translate } from 'react-i18next';
import { NAMESPACES } from '../../i18n';
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { translateTsFuelTypeClassificator } from '../../utils/translateHelpers';
import * as actions from '../../actions/actionCreators/accountGOSAuctionActionCreators';
import {
  getAccountGOSAuctionsList,
  getAccountGOSAuctionsListData,
  getCompletedAuctionsResultData,
} from '../../reducers/accountGOSAuctionsListReducer';
import { getLoadingSelector } from '../../utils/asyncHelpers';
import PropTypesImmutable from 'react-immutable-proptypes';
import FuelType from '../../constants/classificators/fuelType';
import { isAdmin } from '../../utils/roles';
import { getRole } from '../../reducers/userReducer';

export class AccountGOSAuctionsList extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    tsAuctionsList: PropTypesImmutable.map.isRequired,
    tsAuctionsListData: PropTypesImmutable.list,
    registerCode: PropTypes.string,
    isUserAdmin: PropTypes.bool.isRequired,
  };

  componentDidMount() {
    const {
      searchAccountGOSAuctions,
      getLast10CompletedAuctionResults,
    } = this.props;
    searchAccountGOSAuctions(0);
    getLast10CompletedAuctionResults();
  }

  // Graph hover and timer conflict
  shouldComponentUpdate(nextProps, nextState) {
    return (
      this.props.tsAuctionsList !== nextProps.tsAuctionsList ||
      JSON.stringify(this.props.completedAuctionsResult) !==
        JSON.stringify(nextProps.completedAuctionsResult)
    );
  }

  render() {
    const {
      tsAuctionsList,
      tsAuctionsListData,
      tsAuctionsLoading,
      searchAccountGOSAuctions,
      completedAuctionsResult,
      isUserAdmin,
      ...rest
    } = this.props;
    const { t } = this.props;

    function getXAxis() {
      return (
        <XAxis
          dataKey={'endDate'}
          angle={270}
          dx={-10}
          dy={40}
          interval={0}
          height={75}
          axisLine={true}
          tickSize={0}
          tickFormatter={val => val.split(' ')[0]}
        />
      );
    }

    return (
      <React.Fragment>
        <AccountGOSAuctionsSearchTable
          tsAuctions={tsAuctionsListData.toJS()}
          search={searchAccountGOSAuctions}
          {...rest}
          {...tsAuctionsList.toJS()}
        />
        {!isUserAdmin && (
          <span>
            <h4 style={{ textAlign: 'center' }}>{t('chart.unitPrice')}</h4>
            <div style={{ height: 175, width: '90%', margin: 'auto' }}>
              <ResponsiveContainer width={'100%'} height={'100%'}>
                <LineChart
                  data={completedAuctionsResult.filter(i => !i['KHG'])}
                >
                  <CartesianGrid vertical={false} strokeDasharray={'1 0 1'} />
                  {getXAxis()}
                  <YAxis />
                  <Legend
                    layout={'horizontal'}
                    verticalAlign={'top'}
                    margin={{ bottom: 20 }}
                  />
                  <Tooltip labelFormatter={val => val.slice(0, -1)} />
                  <Line
                    type={'monotone'}
                    stroke="#0096FF"
                    activeDot={{ r: 8 }}
                    dot={{ r: 3 }}
                    dataKey={'BIOMETHANE'}
                    connectNulls={true}
                    name={translateTsFuelTypeClassificator(FuelType.BIOMETHANE)}
                  />
                  <Line
                    type={'monotone'}
                    stroke="#F5222D"
                    activeDot={{ r: 8 }}
                    dot={{ r: 3 }}
                    dataKey={'HYDROGEN'}
                    connectNulls={true}
                    name={translateTsFuelTypeClassificator(FuelType.HYDROGEN)}
                  />
                  <Line
                    type={'monotone'}
                    stroke="#00A36C"
                    activeDot={{ r: 8 }}
                    dot={{ r: 3 }}
                    dataKey={'RENEWABLE_ELECTRICITY'}
                    connectNulls={true}
                    name={translateTsFuelTypeClassificator(
                      FuelType.RENEWABLE_ELECTRICITY
                    )}
                  />
                  <Line
                    type={'monotone'}
                    stroke="#FFC300"
                    activeDot={{ r: 8 }}
                    dot={{ r: 3 }}
                    dataKey={'RENEWABLE_ENERGY'}
                    connectNulls={true}
                    name={translateTsFuelTypeClassificator(
                      FuelType.RENEWABLE_ENERGY
                    )}
                  />
                </LineChart>
              </ResponsiveContainer>
            </div>
            <h4 style={{ textAlign: 'center', paddingTop: 25 }}>
              {t('chart.unitPriceKhg')}
            </h4>
            <div style={{ height: 175, width: '90%', margin: 'auto' }}>
              <ResponsiveContainer width={'100%'} height={'100%'}>
                <LineChart data={completedAuctionsResult.filter(i => i['KHG'])}>
                  <CartesianGrid vertical={false} strokeDasharray={'1 0 1'} />
                  {getXAxis()}
                  <YAxis />
                  <Tooltip />
                  <Legend
                    layout={'horizontal'}
                    verticalAlign={'top'}
                    margin={{ bottom: 20 }}
                  />
                  <Line
                    type={'monotone'}
                    stroke="#0096FF"
                    activeDot={{ r: 8 }}
                    dot={{ r: 3 }}
                    dataKey={FuelType.KHG}
                    name={translateTsFuelTypeClassificator(FuelType.KHG)}
                  />
                </LineChart>
              </ResponsiveContainer>
            </div>
          </span>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  tsAuctionsList: getAccountGOSAuctionsList(state),
  tsAuctionsListData: getAccountGOSAuctionsListData(state),
  tsAuctionsLoading: getLoadingSelector(getAccountGOSAuctionsList)(state),
  completedAuctionsResult: getCompletedAuctionsResultData(state),
  isUserAdmin: isAdmin(getRole(state)),
});

const mapDispatchToProps = {
  ...actions,
};

export default connect(mapStateToProps, mapDispatchToProps)(
  translate(NAMESPACES.auctions)(AccountGOSAuctionsList)
);
