import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import './TsAuctionBid.scss';
import { getTsBidDetailsData } from '../../reducers/tsAuctionReducer';
import {
  bidOnAuction,
  fetchBidDetailsById,
} from '../../actions/actionCreators/tsAuctionActionCreators';
import { auctionBidResetMaster } from '../../actions/pureActions/auctionActions';
import { Button, Checkbox, Icon, message, Spin, Tooltip } from 'antd';
import validate from './ValidateSchema';
import { translateTsFuelTypeClassificator } from '../../utils/translateHelpers';
import { TableActions, TableRowActions } from '../../components';
import {
  change,
  Field,
  formValueSelector,
  reduxForm,
} from 'redux-form/immutable';
import { NumberField } from '../../atoms';
import { FORM_TS_AUCTION_BID } from '../../constants/formKeys';
import { formTsAuctionBidFields } from '../../constants/forms/formTsAuctionBidFields';
import { isString } from 'lodash';
import { processAsyncResult } from '../../utils/formHelpers';
import moment from 'moment';
import { fuelType } from '../../constants/classificators';
import { calculateTransactionCost } from '../../components/TsAuctionSearchTable/TsAuctionSearchTableColumns';
import { getInfoModal } from '../../pages/TsAuctionDetailsView/TsAdditionalInfoModal';

export class TsAuctionBid extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    selectedRow: PropTypes.object.isRequired,
    hideBidModal: PropTypes.func.isRequired,
    refreshTable: PropTypes.func.isRequired,
    handleView: PropTypes.func,
    onSuccess: PropTypes.func,
    updateFormValue: PropTypes.func,
  };

  state = {
    isAdditionalInfoVisible: false,
    agreeToConditions: false,
    agreeToAutomaticReport: false,
    agreeToManualReport: false,
    currentTime: moment(),
  };

  componentDidMount() {
    const { selectedRow, fetchTsBidDetails } = this.props;
    fetchTsBidDetails(selectedRow.id).then(data =>
      this.props.updateFormValue(
        formTsAuctionBidFields.UNIT_PRICE,
        data.currentBid ? data.currentBid + data.minBidStep : data.minUnitPrice
      )
    );
  }

  componentWillUnmount() {
    const { resetMaster } = this.props;
    resetMaster();
  }

  handleView = id => {
    const { hideBidModal, handleView } = this.props;
    hideBidModal();
    handleView(id);
  };

  renderRow = (label, value, className = null) => (
    <div className={className}>
      <span>{label}: </span>
      <span>{value}</span>
    </div>
  );

  renderRowWithTooltip = (label, value, tooltipTitle) => (
    <div>
      <span>
        {label}&nbsp;
        <Tooltip title={tooltipTitle}>
          <Icon type="question-circle-o" />
        </Tooltip>
      </span>
      <span>: </span>
      <span>{value}</span>
    </div>
  );

  renderCheckboxWithTooltip = (label, tooltipTitle) => (
    <>
      <span>{label}</span>
      <span style={{ paddingLeft: 4 }}>
        <Tooltip title={tooltipTitle}>
          <Icon type="question-circle-o" />
        </Tooltip>
      </span>
    </>
  );

  handlePriceChange = (e, value, currentBid, bidStep) => {
    if (value && value > currentBid) {
      e.preventDefault(); // Prevent auto updating field value

      const roundedValue = Math.round((value - currentBid) / bidStep) * bidStep;
      this.props.updateFormValue(
        formTsAuctionBidFields.UNIT_PRICE,
        roundedValue + currentBid
      );
    }
  };

  render() {
    const {
      t,
      selectedRow,
      tsBidDetails,
      hideBidModal,
      handleSubmit,
      newUnitPrice,
    } = this.props;
    const {
      agreeToConditions,
      agreeToAutomaticReport,
      agreeToManualReport,
      isAdditionalInfoVisible,
      currentTime,
    } = this.state;

    if (tsBidDetails == null) return <Spin />;
    const data = tsBidDetails.toJS();

    const isKhg = data.fuelType === fuelType.KHG;
    const khg = isKhg ? 'Khg' : '';

    return (
      <form onSubmit={handleSubmit}>
        {getInfoModal.call(
          this,
          isAdditionalInfoVisible,
          t('bidModal.additionalInfo'),
          data.additionalInfo
        )}

        <div className="detail-container">
          {data.highestBidder && (
            <>
              <div>
                <strong>
                  {currentTime.format('DD.MM.YYYY hh.mm.ss')}{' '}
                  {t('bidModal.highestBidder')}
                </strong>
              </div>
              <br />
            </>
          )}
          <div>
            <span>{t('bidModal.id')}: </span>
            <a onClick={() => this.handleView(selectedRow.id)}>
              {selectedRow.id}
            </a>
          </div>
          {this.renderRow(t('bidModal.createdDate'), data.createdDate)}
          {this.renderRow(t('bidModal.endDate'), data.endDate)}
          <br />
          {this.renderRow(t('bidModal.sellerName'), data.sellerName)}
          {this.renderRow(t('bidModal.sellerCode'), data.sellerCode)}
          <br />
          {this.renderRow(
            t('bidModal.fuelType'),
            translateTsFuelTypeClassificator(data.fuelType)
          )}
          {!isKhg ? (
            <>
              {this.renderRow(
                t('bidModal.naturalAmountMj'),
                data.naturalAmountMj.toFixed(1)
              )}
              {this.renderRow(
                t('bidModal.accountedAmountMj'),
                data.accountedAmountMj.toFixed(1)
              )}
              {selectedRow.ghgCapacity
                ? this.renderRow(
                    t('bidModal.ghgCapacity'),
                    selectedRow.ghgCapacity.toFixed(1)
                  )
                : null}
            </>
          ) : (
            <>
              {this.renderRow(
                t('bidModal.ghgAmount'),
                data.ghgAmount.toFixed(1)
              )}
            </>
          )}
          {this.renderRow(t('bidModal.expirationDate'), data.expirationDate)}
          <br />
          {this.renderRow(
            t('bidModal.currentBidDate'),
            data.currentBidDate ?? '-'
          )}
          {this.renderRowWithTooltip(
            t('bidModal.currentUnitPrice' + khg),
            data.currentBid ? data.currentBid.toFixed(3) : '-',
            t('bidModal.currentUnitPriceTooltip' + khg)
          )}
          {this.renderRowWithTooltip(
            t('bidModal.currentTransactionCost'),
            data.currentBid
              ? calculateTransactionCost(data, data.currentBid, isKhg).toFixed(
                  2
                )
              : '-',
            t('bidModal.currentTransactionCostTooltip')
          )}
          <br />
          <Field
            disabled={data.biddingDisabled}
            min={
              data.currentBid
                ? data.currentBid + data.minBidStep
                : data.minUnitPrice
            }
            step={data.minBidStep}
            precision={3}
            name={formTsAuctionBidFields.UNIT_PRICE}
            label={t('bidModal.newUnitPrice' + khg)}
            tooltip={t('bidModal.unitPriceTooltip' + khg)}
            placeholder={t('bidModal.newUnitPricePlaceholder')}
            component={NumberField}
            onBlur={(event, value) =>
              this.handlePriceChange(
                event,
                value,
                data.currentBid ?? data.minUnitPrice,
                data.minBidStep
              )
            }
            isFloat
            blurAllowed
          />
          {this.renderRowWithTooltip(
            t('bidModal.newTransactionCost'),
            (newUnitPrice
              ? calculateTransactionCost(data, newUnitPrice, isKhg)
              : 0
            ).toFixed(2),
            t('bidModal.newTransactionCostTooltip')
          )}
          <br />
          {this.renderRow(
            t('bidModal.additionalInfo'),
            <TableRowActions>
              <Button
                shape="circle"
                icon="select"
                size="small"
                type="primary"
                style={{ marginLeft: '8px' }}
                title={t('bidModal.additionalInfo')}
                onClick={() => this.setState({ isAdditionalInfoVisible: true })}
              />
            </TableRowActions>,
            'ant-row-flex'
          )}
          <br />
        </div>
        {!data.biddingDisabled && (
          <>
            <Checkbox
              onChange={event =>
                this.setState({ agreeToConditions: event.target.checked })
              }
              checked={agreeToConditions}
              className="mb-3"
              style={{ marginLeft: 0 }}
            >
              {t('bidModal.confirmCheckbox')}
            </Checkbox>
            <Checkbox
              disabled={agreeToManualReport}
              name={formTsAuctionBidFields.AGREE_TO_AUTOMATIC_REPORT}
              onChange={event => {
                this.setState({ agreeToAutomaticReport: event.target.checked });
                this.props.updateFormValue(
                  formTsAuctionBidFields.AGREE_TO_AUTOMATIC_REPORT,
                  event.target.checked
                );
              }}
              checked={agreeToAutomaticReport}
              className="mb-3"
              style={{ marginLeft: 0 }}
            >
              {this.renderCheckboxWithTooltip(
                t('bidModal.agreeToAutomaticReport'),
                t('bidModal.agreeToAutomaticReportTooltip')
              )}
            </Checkbox>
            <Checkbox
              disabled={agreeToAutomaticReport}
              name={formTsAuctionBidFields.AGREE_TO_MANUAL_REPORT}
              onChange={event => {
                this.setState({ agreeToManualReport: event.target.checked });
                this.props.updateFormValue(
                  formTsAuctionBidFields.AGREE_TO_MANUAL_REPORT,
                  event.target.checked
                );
              }}
              checked={agreeToManualReport}
              className="mb-3"
              style={{ marginLeft: 0, display: 'inline-block' }}
            >
              {this.renderCheckboxWithTooltip(
                t('bidModal.agreeToManualReport'),
                t('bidModal.agreeToManualReportTooltip')
              )}
            </Checkbox>
          </>
        )}
        <TableActions>
          <Button onClick={() => hideBidModal()}>{t('bidModal.cancel')}</Button>
          {!data.biddingDisabled && (
            <Button
              disabled={
                !agreeToConditions ||
                !newUnitPrice ||
                (!agreeToManualReport && !agreeToAutomaticReport)
              }
              type="primary"
              htmlType="submit"
              className="global-margin-left-10"
            >
              {t('bidModal.confirm')}
            </Button>
          )}
        </TableActions>
      </form>
    );
  }
}

const onSubmit = async (values, dispatch, props) => {
  const res = await dispatch(
    bidOnAuction({
      auctionId: props.selectedRow.id,
      unitPrice: values.toJS().unitPrice,
      agreeToAutomaticReport: props.agreeToAutomaticReport,
      agreeToManualReport: props.agreeToManualReport,
    })
  );

  processAsyncResult(res);
};

const onSubmitSuccess = (result, dispatch, props) => {
  const { hideBidModal, t } = props;
  message.success(t('bidModal.performSuccess'));
  hideBidModal();
  props.refreshTable();
};

const onSubmitFail = errors => {
  if (isString(errors)) {
    message.error(errors);
  }
};

const mapStateToProps = state => ({
  tsBidDetails: getTsBidDetailsData(state),
  newUnitPrice: formValueSelector(FORM_TS_AUCTION_BID)(
    state,
    formTsAuctionBidFields.UNIT_PRICE
  ),
  agreeToAutomaticReport:
    formValueSelector(FORM_TS_AUCTION_BID)(
      state,
      formTsAuctionBidFields.AGREE_TO_AUTOMATIC_REPORT
    ) ?? false,
  agreeToManualReport:
    formValueSelector(FORM_TS_AUCTION_BID)(
      state,
      formTsAuctionBidFields.AGREE_TO_MANUAL_REPORT
    ) ?? false,
});

const mapDispatchToProps = dispatch => ({
  updateFormValue: (fieldName, value) =>
    dispatch(change(FORM_TS_AUCTION_BID, fieldName, value)),
  fetchTsBidDetails: id => dispatch(fetchBidDetailsById(id)),
  resetMaster: () => dispatch(auctionBidResetMaster()),
});

export default connect(mapStateToProps, mapDispatchToProps)(
  reduxForm({
    form: FORM_TS_AUCTION_BID,
    validate,
    onSubmit,
    onSubmitSuccess,
    onSubmitFail,
  })(TsAuctionBid)
);
