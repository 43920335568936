import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Table } from 'antd';
import { auctionFields } from '../../constants/auction';
import { goBack } from '../../utils/gotoLink';
import { TableActions, TableRowActions } from '../../components';
import FuelType from '../../constants/classificators/fuelType';
import { translateTsFuelGenerationClassificator } from '../../utils/translateHelpers';
import { numberFormatter } from '../../utils/formaters';

export class TsAuctionStatistics extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    fuelType: PropTypes.string,
    statistics: PropTypes.array.isRequired,
    isStatisticsLoading: PropTypes.bool.isRequired,
    fetchOverview: PropTypes.func.isRequired,
  };

  getConsumptionPeriod(record) {
    const parseMonth = month => (month ? ('0' + month).slice(-2) + '.' : null);
    return record.isSummary ? null : parseMonth(record.month) + record.year;
  }

  renderAmount(value, isSummary, show) {
    const { t } = this.props;
    return (isSummary && show ? t('details.total') + ': ' : '') + value;
  }

  getColumns = () => {
    const { t, fuelType } = this.props;
    const columns = [];

    const isKhg = fuelType === FuelType.KHG;
    const isB = fuelType === FuelType.BIOMETHANE;
    const isH = fuelType === FuelType.HYDROGEN;
    const isE = fuelType === FuelType.RENEWABLE_ELECTRICITY;
    const isS = fuelType === FuelType.RENEWABLE_ENERGY;

    if (isH)
      columns.push({
        title: t('details.hydrogenType'),
        dataIndex: auctionFields.FUEL_GENERATION,
        render: value => {
          return translateTsFuelGenerationClassificator(value);
        },
      });

    if (isKhg)
      columns.push({
        title: t('details.ghgAmount'),
        dataIndex: auctionFields.GHG_AMOUNT,
        render: (value, record) => {
          return this.renderAmount(
            numberFormatter(value),
            record.isSummary,
            isKhg || isS
          );
        },
      });

    if (isB || isS)
      columns.push({
        title: t('details.fuelGeneration'),
        dataIndex: auctionFields.FUEL_GENERATION,
        render: (value, record) => {
          return record.isSummary
            ? t('details.total') + ':'
            : translateTsFuelGenerationClassificator(value);
        },
      });

    if (!isKhg)
      columns.push(
        {
          title: t('details.naturalAmountMj'),
          dataIndex: auctionFields.NATURAL_AMOUNT_MJ,
          render: (value, record) => {
            return this.renderAmount(
              numberFormatter(value),
              record.isSummary,
              isKhg || isE
            );
          },
        },
        {
          title: t('details.multiplier'),
          dataIndex: auctionFields.MULTIPLIER,
        },
        {
          title: t('details.accountedAmountMj'),
          dataIndex: auctionFields.ACCOUNTED_AMOUNT_MJ,
          render: (txId, record) => {
            return numberFormatter(
              record.isSummary
                ? record.accountedAmountMj
                : record.naturalAmountMj * record.multiplier
            );
          },
        },
        {
          title: t('details.ghgCapacity'),
          dataIndex: auctionFields.GHG_CAPACITY,
        }
      );

    if (isB || isE)
      columns.push({
        title: t('details.consumptionPeriod'),
        dataIndex: auctionFields.CONSUMPTION_PERIOD,
        render: (txId, record) => {
          return this.getConsumptionPeriod(record);
        },
      });

    columns.push({
      title: t('details.actions'),
      dataIndex: auctionFields.ACTIONS,
      render: (txId, record) => {
        return (
          <TableRowActions>
            <Button
              hidden={record.isSummary}
              shape="circle"
              icon="select"
              size="small"
              style={{ marginLeft: '16px' }}
              type="primary"
              title={t('details.viewBtn')}
              onClick={() => this.props.fetchOverview(record, fuelType)}
            />
          </TableRowActions>
        );
      },
    });

    return columns;
  };

  render() {
    const { t, statistics, isStatisticsLoading } = this.props;

    return (
      <React.Fragment>
        <h2>{t('details.tsTableTitle')}</h2>
        <Table
          scroll={{ x: 'auto' }}
          rowKey={auctionFields.ID}
          dataSource={statistics}
          columns={this.getColumns()}
          pagination={false}
          loading={isStatisticsLoading}
          rowClassName={(rec, idx) =>
            idx === statistics.length - 1 ? 'summary-row' : ''
          }
        />
        <TableActions>
          <Button onClick={goBack}>{t('details.buttonBack')}</Button>
        </TableActions>
      </React.Fragment>
    );
  }
}
