import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, InputNumber, message, Modal, Popconfirm, Table } from 'antd';
import { TableActions, TableRowActions } from '../../components';
import {
  goBack,
  goToAccountGOSPreTradingAgreements,
} from '../../utils/gotoLink';
import moment from 'moment';
import {
  translateBiofuelTypeClassificator,
  translateFeedstockClassificator,
  translateFuelTypeClassificator,
  translateHydrogenTechnologyTypeClassificator,
  translateProductionPathwayClassificator,
} from '../../utils/translateHelpers';
import { availableStatisticsTableFields } from '../../constants/availableStatisticsTableFields';
import _ from 'lodash';
import {
  getReservationsConfirmModal,
  getTransportStatisticsDetailsModal,
  hideReservationsConfirmModal,
  hideTransportStatisticsDetailsModal,
  showReservationsConfirmModal,
  showTransportStatisticsDetailsModal,
} from '../../actions/actionCreators/modalsActionCreators';
import { connect } from 'react-redux';
import { TsReservationsConfirm } from '../../forms/';
import { reserve } from '../../actions/actionCreators/accountGOSAvailableStatisticsActionCreators';
import { fetchAccountGOSTsAssignmentCount } from '../../actions/actionCreators/accountGOSTsAssignmentsActionCreators';
import { TransportStatisticsDetailsModal } from '../TransportStatisticsDetailsModal/TransportStatisticsDetailsModal';
import './PreTradingAgreementReservations.scss';
import { groupTs } from '../../utils/tsGroupHelpers';

export class PreTradingAgreementReservations extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    registerCode: PropTypes.string.isRequired,
    agreement: PropTypes.object.isRequired,
    statistics: PropTypes.array.isRequired,
    isStatisticsLoading: PropTypes.bool.isRequired,
    confirmTransactionModal: PropTypes.bool.isRequired,
    showConfirmTransactionModal: PropTypes.func.isRequired,
    fetchStatistics: PropTypes.func.isRequired,
    reserve: PropTypes.func.isRequired,
    isDetailsModalVisible: PropTypes.bool.isRequired,
    showDetailsModal: PropTypes.func.isRequired,
    hideDetailsModal: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      data: this.updateSummary(
        groupTs(props.statistics, props.agreement.fuelType)
      ),
      modalDetails: {},
      modalDetailsVisible: false,
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.isInitialLoad(prevProps)) {
      this.updateData(
        groupTs(this.props.statistics, this.props.agreement.fuelType)
      );
    }
  }

  isInitialLoad(prevProps) {
    return (
      this.props.statistics.length > 0 && prevProps.statistics.length === 0
    );
  }

  updateData(data) {
    this.setState({
      data: this.updateSummary(data),
    });
  }

  updateSummary(data) {
    const naturalAmount =
      availableStatisticsTableFields.NATURAL_ENERGY_TRANSFER_AMOUNT;
    const calculatedAmount =
      availableStatisticsTableFields.CALCULATED_ENERGY_TRANSFER_AMOUNT;

    let summary = this.getOrCreateSummary(data);
    summary[naturalAmount] = this.sum(naturalAmount, data);
    summary[calculatedAmount] = this.sum(calculatedAmount, data);

    return data;
  }

  createSummary() {
    const { t } = this.props;

    return {
      isSummary: true,
      statisticsId: 0,
      calculatedMultiplier: t('table.total') + ':',
    };
  }

  getOrCreateSummary(data) {
    let summary = data.find(row => row.isSummary);
    if (!summary) {
      summary = this.createSummary();
      data.push(summary);
    }
    return summary;
  }

  sum(field, data) {
    return data
      .filter(row => !row.isSummary)
      .reduce((sum, current) => _.get(current, field) + sum, 0)
      .toFixed(1);
  }

  buildReservationRequest() {
    return {
      transferNow: false,
      tsAgreementId: this.props.agreement.id,
      unitPrice: this.props.agreement.unitPrice,
      tsPairs: this.mapToTsPairs(this.state.data.filter(row => !row.isSummary)),
    };
  }

  mapToTsPairs(data) {
    return data.map(row => {
      return {
        tsIds: row[availableStatisticsTableFields.GROUPED_STATISTICS_IDS],
        amount:
          row[availableStatisticsTableFields.NATURAL_ENERGY_TRANSFER_AMOUNT],
      };
    });
  }

  onReservationSuccess(successMessage) {
    const { t, statistics, fetchStatistics, fetchAssignmentCount } = this.props;

    message.success(t(successMessage));

    fetchAssignmentCount();
    fetchStatistics();

    this.updateData(statistics);
  }

  async onReserve() {
    const { t, registerCode, fetchAssignmentCount } = this.props;

    const result = await this.props.reserve(this.buildReservationRequest());

    if (result.error) {
      message.destroy();
      return message.error(t(result.error));
    } else {
      message.success(t('reserveSuccess'));
      fetchAssignmentCount();
      goToAccountGOSPreTradingAgreements(registerCode);
    }
  }

  onReserveAndConfirm() {
    const { showConfirmTransactionModal } = this.props;
    showConfirmTransactionModal();
  }

  onRemove = id => {
    const { t } = this.props;
    const data = this.state.data.slice();

    if (data.length <= 2) {
      message.warning(t('warningOne'));
    } else {
      this.updateData(
        data.filter(row => row[availableStatisticsTableFields.ID] !== id)
      );
    }
  };

  onValueMjChange = (value, id) => {
    const data = this.state.data.slice();
    const row = data.find(row => row[availableStatisticsTableFields.ID] === id);

    const naturalMj = parseFloat(value) || 0;
    const multiplier =
      row[availableStatisticsTableFields.CALCULATED_MULTIPLIER];

    row[
      availableStatisticsTableFields.NATURAL_ENERGY_TRANSFER_AMOUNT
    ] = naturalMj;
    row[availableStatisticsTableFields.CALCULATED_ENERGY_TRANSFER_AMOUNT] =
      naturalMj * multiplier;

    this.updateData(data);
  };

  getColumns = () => {
    const { t, agreement } = this.props;

    const columns = [];

    columns.push({
      title: t('table.expirationTime'),
      dataIndex: availableStatisticsTableFields.EXPIRATION_DATE,
    });

    if (agreement.fuelType === 'BIOMETHANE') {
      columns.push(
        {
          title: t('table.eicwCode'),
          dataIndex: availableStatisticsTableFields.PRODUCTION_UNIT_EICW_CODE,
        },
        {
          title: t('table.productionTime'),
          dataIndex: availableStatisticsTableFields.PRODUCTION_PERIOD,
          render: v => (v ? moment(v, 'DD.MM.YYYY').format('MM.YYYY') : ''),
        },
        {
          title: t('table.rawMaterialType'),
          dataIndex: availableStatisticsTableFields.FUEL_TYPE,
          render: translateFuelTypeClassificator,
        },
        {
          title: t('table.rawMaterial'),
          dataIndex: availableStatisticsTableFields.FEEDSTOCK,
          render: translateFeedstockClassificator,
        },
        {
          title: t('table.productionPathway'),
          dataIndex: availableStatisticsTableFields.PRODUCTION_PATHWAY,
          render: translateProductionPathwayClassificator,
        }
      );
    }

    if (agreement.fuelType === 'HYDROGEN') {
      columns.push(
        {
          title: t('table.eicwCode'),
          dataIndex: availableStatisticsTableFields.PRODUCTION_UNIT_EICW_CODE,
        },
        {
          title: t('table.productionTime'),
          dataIndex: availableStatisticsTableFields.PRODUCTION_PERIOD,
          render: v => (v ? moment(v, 'DD.MM.YYYY').format('MM.YYYY') : ''),
        },
        {
          title: t('table.hydrogenTechnologyType'),
          dataIndex: availableStatisticsTableFields.HYDROGEN_TECHNOLOGY_TYPE,
          render: translateHydrogenTechnologyTypeClassificator,
        }
      );
    }

    if (
      agreement.fuelType === 'BIOMETHANE' ||
      agreement.fuelType === 'HYDROGEN' ||
      agreement.fuelType === 'RENEWABLE_ENERGY'
    ) {
      columns.push({
        title: t('table.fuelGeneration'),
        dataIndex: availableStatisticsTableFields.FUEL_GENERATION,
        render: translateBiofuelTypeClassificator,
      });
    }

    if (
      agreement.fuelType === 'BIOMETHANE' ||
      agreement.fuelType === 'RENEWABLE_ELECTRICITY'
    ) {
      columns.push({
        title: t('table.consumptionTime'),
        render: (_, row) => {
          const month = row[availableStatisticsTableFields.CONSUMPTION_MONTH];
          const year = row[availableStatisticsTableFields.CONSUMPTION_YEAR];

          return [month ? month.padStart(2, 0) : undefined, year]
            .filter(Boolean)
            .join('.');
        },
      });
    }

    columns.push(
      {
        title: t('table.calculatedMultiplier'),
        dataIndex: availableStatisticsTableFields.CALCULATED_MULTIPLIER,
      },
      {
        title: t('table.naturalQuantityMj'),
        dataIndex:
          availableStatisticsTableFields.NATURAL_ENERGY_TRANSFER_AMOUNT,
        render: (value, row) => {
          const ID = row[availableStatisticsTableFields.ID];
          const maxAmount =
            row[availableStatisticsTableFields.NATURAL_ENERGY_AMOUNT];
          return row.isSummary ? (
            value
          ) : (
            <InputNumber
              min={0.1}
              step={0.1}
              precision={1}
              max={maxAmount}
              value={value}
              style={{ width: '100%' }}
              onChange={numberValueMj => {
                this.onValueMjChange(numberValueMj, ID);
              }}
            />
          );
        },
      },
      {
        title: t('table.calculatedEnergyAmountMj'),
        dataIndex:
          availableStatisticsTableFields.CALCULATED_ENERGY_TRANSFER_AMOUNT,
      }
    );

    columns.push({
      title: t('table.actions'),
      width: 100,
      dataIndex: availableStatisticsTableFields.ID,
      render: (value, row) =>
        !row.isSummary && (
          <TableRowActions>
            <Button
              shape="circle"
              icon="delete"
              size="small"
              type="primary"
              title={t('table.deleteBtn')}
              onClick={() => this.onRemove(value)}
            />
            <Button
              shape="circle"
              icon="select"
              size="small"
              style={{ marginLeft: '8px' }}
              type="primary"
              title={t('table.viewBtn')}
              onClick={() => this.handleShowDetailsModal(row)}
            />
          </TableRowActions>
        ),
    });

    return columns;
  };

  render() {
    const { data, modalDetails } = this.state;
    const {
      t,
      agreement,
      registerCode,
      isStatisticsLoading,
      confirmTransactionModal,
      hideConfirmTransactionModal,
      isDetailsModalVisible,
      hideDetailsModal,
    } = this.props;

    return (
      <React.Fragment>
        {isDetailsModalVisible && (
          <TransportStatisticsDetailsModal
            t={t}
            agreementData={agreement}
            transportStatisticsData={modalDetails}
            isTransportStatisticsDataLoading={false}
            hideModal={hideDetailsModal}
          />
        )}
        {confirmTransactionModal && (
          <Modal
            onCancel={hideConfirmTransactionModal}
            visible
            footer={false}
            title={t('confirm.title')}
            witdth="600px"
          >
            <TsReservationsConfirm
              t={t}
              agreement={agreement}
              statistics={data.filter(row => !row.isSummary)}
              registerCode={registerCode}
              onSuccess={this.onReservationSuccess.bind(
                this,
                'reserveAndConfirmSuccess'
              )}
              hideModal={hideConfirmTransactionModal}
            />
          </Modal>
        )}
        <h2>{t('table.title')}</h2>
        <Table
          scroll={{ x: 'auto' }}
          rowKey={availableStatisticsTableFields.ID}
          dataSource={data}
          columns={this.getColumns()}
          pagination={false}
          loading={isStatisticsLoading}
          rowClassName={(rec, idx) =>
            idx === data.length - 1 ? 'summary-row' : ''
          }
        />
        <TableActions>
          <Button onClick={goBack}>{t('buttonBack')}</Button>
          <Button
            type="primary"
            className="global-margin-left-10"
            disabled={data.length === 0}
            onClick={this.onReserveAndConfirm.bind(this)}
          >
            {t('buttonReserveAndConfirm')}
          </Button>
          <Popconfirm
            title={t('confirmReserve')}
            onConfirm={this.onReserve.bind(this)}
            okText={t('buttonYes')}
            cancelText={t('buttonNo')}
          >
            <Button
              type="primary"
              className="global-margin-left-10"
              disabled={data.length === 0}
            >
              {t('buttonReserve')}
            </Button>
          </Popconfirm>
        </TableActions>
      </React.Fragment>
    );
  }

  handleShowDetailsModal = details => {
    this.setState({ modalDetails: details });
    this.props.showDetailsModal();
  };
}

const mapStateToProps = state => ({
  confirmTransactionModal: getReservationsConfirmModal(state),
  isDetailsModalVisible: getTransportStatisticsDetailsModal(state),
});

const mapDispatchToProps = dispatch => ({
  showConfirmTransactionModal: () => dispatch(showReservationsConfirmModal()),
  hideConfirmTransactionModal: () => dispatch(hideReservationsConfirmModal()),
  showDetailsModal: () => dispatch(showTransportStatisticsDetailsModal()),
  hideDetailsModal: () => dispatch(hideTransportStatisticsDetailsModal()),
  reserve: data => dispatch(reserve(data)),
  fetchAssignmentCount: () => dispatch(fetchAccountGOSTsAssignmentCount()),
});

export default connect(mapStateToProps, mapDispatchToProps)(
  PreTradingAgreementReservations
);
