export const availableStatisticsTableFields = {
  ID: 'statisticsId',
  EXPIRATION_DATE: 'expirationDate',
  PRODUCTION_UNIT_EICW_CODE: 'productionUnitEicwCode',
  PRODUCTION_PERIOD: 'productionPeriod',
  CONSUMPTION_MONTH: 'consumptionMonth',
  CONSUMPTION_YEAR: 'consumptionYear',
  CONSUMPTION_PROVIDER: 'consumptionProvider',
  CONSUMPTION_PROVIDER_TRANSACTION_ID: 'consumptionProviderTransactionId',
  FUEL_TYPE: 'fuelType',
  FEEDSTOCK: 'feedstock',
  FUEL_GENERATION: 'fuelGeneration',
  HYDROGEN_TECHNOLOGY_TYPE: 'hydrogenTechnologyType',
  PRODUCTION_PATHWAY: 'productionPathway',
  LAND_USE_CATEGORY: 'landUseCategory',

  NATURAL_ENERGY_AMOUNT: 'naturalAmountMj',
  CALCULATED_ENERGY_AMOUNT: 'calculatedAmountMj',
  CALCULATED_MULTIPLIER: 'calculatedMultiplier',

  NATURAL_ENERGY_TRANSFER_AMOUNT: 'naturalEnergyTransferAmount',
  CALCULATED_ENERGY_TRANSFER_AMOUNT: 'calculatedEnergyTransferAmount',

  GROUPED_STATISTICS_IDS: 'groupedStatisticsIds',
};
