import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, Col, Icon, Row, Spin, Tooltip } from 'antd';
import { agreementFields } from '../../constants/agreement';
import {
  getAgreementData,
  getAgreementTransactionList,
  getAgreementTransactionsEntities,
} from '../../reducers/agreementViewReducer';
import * as actions from '../../actions/actionCreators/agreementViewActionCreators';
import { resetMaster as agreementResetMaster } from '../../actions/actionCreators/agreementViewActionCreators';
import PropTypesImmutable from 'react-immutable-proptypes';
import {
  translateAgreementTypeClassificator,
  translateBiofuelTypeClassificator,
  translateTsFuelTypeClassificator,
  translateTsStatusClassificator,
  translateWithdrawTypeClassificator,
} from '../../utils/translateHelpers';
import { TableActions } from '../../components';
import {
  goToAccountGOSPreTradingAgreements,
  goToTradingPlatformAgreements,
} from '../../utils/gotoLink';
import { TransportStatisticsList } from '../index';
import { getLoadingSelector } from '../../utils/asyncHelpers';
import './PreTradingAgreementView.scss';
import { agreementWithdrawalFields } from '../../constants/agreementWithdrawal';
import { withdrawType } from '../../constants/classificators';
import FuelType from '../../constants/classificators/fuelType';
import { isAdmin } from '../../utils/roles';
import { getRole } from '../../reducers/userReducer';

export class PreTradingAgreementView extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    agreementId: PropTypes.string.isRequired,
    registerCode: PropTypes.string.isRequired,
    fetchAgreement: PropTypes.func.isRequired,
    agreementData: PropTypesImmutable.map,
  };

  componentDidMount = () => {
    const {
      agreementId,
      fetchAgreement,
      fetchAgreementTransactions,
    } = this.props;
    fetchAgreement(agreementId);
    fetchAgreementTransactions(agreementId, { page: 0, size: 10 });
  };

  componentWillUnmount() {
    const { resetAgreement } = this.props;
    resetAgreement();
  }

  isOfType(withdrawal, type) {
    return withdrawal && withdrawal[agreementWithdrawalFields.TYPE] === type;
  }

  render() {
    const {
      t,
      transactions,
      transactionsState,
      isTransactionsLoading,
      agreementData,
      isAgreementDataLoading,
      fetchAgreementTransactions,
      isUserAdmin,
      ...rest
    } = this.props;

    const dataJS = agreementData.toJS();

    const withdrawal = dataJS[agreementFields.AGREEMENT_WITHDRAWAL];

    const isBilateralWithdrawal = this.isOfType(
      withdrawal,
      withdrawType.BILATERAL
    );
    const isAgreementViolation = this.isOfType(
      withdrawal,
      withdrawType.AGREEMENT_VIOLATION
    );
    const isAgreementUnfulfilled = this.isOfType(
      withdrawal,
      withdrawType.AGREEMENT_UNFULFILLED
    );

    if (isAgreementDataLoading) {
      return <Spin spinning />;
    }

    return (
      <React.Fragment>
        <h2>{t('title_details')}</h2>
        <Row className="global-margin-bottom-10 global-margin-top-24">
          <Col span={6}>
            <b>{t('info.agreementId')}: </b>
            {dataJS[agreementFields.ID]}
          </Col>
          <Col span={6}>
            <b>{t('info.agreementType')}: </b>
            {translateAgreementTypeClassificator(
              dataJS[agreementFields.AGREEMENT_TYPE]
            )}
          </Col>

          <Col span={6}>
            <b>{t('info.status')}: </b>
            {translateTsStatusClassificator(dataJS[agreementFields.STATUS])}
          </Col>
        </Row>

        <Row className="global-margin-bottom-10">
          <Col span={6}>
            {isUserAdmin && (
              <>
                <b>{t('info.sellerName')}: </b>
                {dataJS[agreementFields.SELLER_NAME]}
              </>
            )}
            {!isUserAdmin && (
              <>
                <b>{t('info.counterpartyName')}: </b>
                {dataJS[agreementFields.COUNTERPARTY_NAME]}
              </>
            )}
          </Col>
          <Col span={6}>
            <b>{t('info.createdAt')}: </b>
            {dataJS[agreementFields.CREATED_AT]}
          </Col>
          <Col span={6}>
            {isUserAdmin && (
              <>
                <b>{t('info.buyerName')}: </b>
                {dataJS[agreementFields.BUYER_NAME]}
              </>
            )}
          </Col>
        </Row>

        <Row className="global-margin-bottom-10">
          <Col span={6}>
            {isUserAdmin && (
              <>
                <b>{t('info.sellerCode')}: </b>
                {dataJS[agreementFields.SELLER_CODE]}
              </>
            )}
            {!isUserAdmin && (
              <>
                <b>{t('info.counterpartyRegisterCode')}: </b>
                {dataJS[agreementFields.COUNTERPARTY_REGISTER_CODE]}
              </>
            )}
          </Col>
          <Col span={6}>
            <b>{t('info.registeredAt')}: </b>
            {dataJS[agreementFields.REGISTERED_AT]}
          </Col>
          <Col span={6}>
            {isUserAdmin && (
              <>
                <b>{t('info.buyerCode')}: </b>
                {dataJS[agreementFields.BUYER_CODE]}
              </>
            )}
          </Col>
        </Row>

        {withdrawal && (
          <span>
            <h2 className="global-margin-top-42">
              {t('info.agreementWithdrawal.title')}
            </h2>

            <Row className="global-margin-bottom-10 global-margin-top-24">
              <Col span={6}>
                <b>{t('info.agreementWithdrawal.type')}: </b>
                {translateWithdrawTypeClassificator(
                  withdrawal[agreementWithdrawalFields.TYPE]
                )}
              </Col>
              {(isBilateralWithdrawal || isAgreementViolation) && (
                <Col span={6}>
                  <b>{t('info.agreementWithdrawal.createdAt')}: </b>
                  {withdrawal[agreementWithdrawalFields.CREATED_AT]}
                </Col>
              )}
              {isAgreementUnfulfilled && (
                <Col span={6}>
                  <b>
                    {t('info.agreementWithdrawal.unfulfilledNaturalAmountMj')}:{' '}
                  </b>
                  {dataJS[agreementFields.NATURAL_AMOUNT_MJ] -
                    dataJS[agreementFields.FULFILLED_NATURAL_AMOUNT_MJ]}
                </Col>
              )}
              {isAgreementUnfulfilled && (
                <Col span={6}>
                  <b>
                    {t(
                      'info.agreementWithdrawal.unfulfilledCalculatedAmountMj'
                    )}:{' '}
                  </b>
                  {dataJS[agreementFields.CALCULATED_AMOUNT_MJ] -
                    dataJS[agreementFields.FULFILLED_CALCULATED_AMOUNT_MJ]}
                </Col>
              )}

              {isBilateralWithdrawal && (
                <Col span={6}>
                  <b>{t('info.agreementWithdrawal.effectiveAt')}: </b>
                  {withdrawal[agreementWithdrawalFields.EFFECTIVE_AT]}
                </Col>
              )}
            </Row>

            {(isBilateralWithdrawal || isAgreementViolation) && (
              <Row className="global-margin-bottom-10 column">
                <Col span={6}>
                  <b>{t('info.agreementWithdrawal.petitioner')}: </b>
                  {withdrawal[agreementWithdrawalFields.PETITIONER]}
                </Col>
                {isBilateralWithdrawal && (
                  <Col span={6}>
                    <b>{t('info.agreementWithdrawal.confirmedAt')}: </b>
                    {withdrawal[agreementWithdrawalFields.CONFIRMED_AT]}
                  </Col>
                )}
              </Row>
            )}

            {(isBilateralWithdrawal || isAgreementViolation) && (
              <Row className="global-margin-bottom-10 column">
                <Col span={18}>
                  <b>{t('info.agreementWithdrawal.reason')}: </b>
                  {withdrawal[agreementWithdrawalFields.REASON]}
                </Col>
              </Row>
            )}
          </span>
        )}

        <h2 className="global-margin-top-42">{t('title_conditions')}</h2>

        <div className="conditions">
          <Row className="global-margin-bottom-10 global-margin-top-24 column">
            <Col>
              <b>{t('info.finalConsumptionYear')}: </b>
              {dataJS[agreementFields.FINAL_CONSUMPTION_YEAR]}
            </Col>
            <Col>
              <b>{t('info.fuelType')}: </b>
              {translateTsFuelTypeClassificator(
                dataJS[agreementFields.FUEL_TYPE]
              )}
            </Col>
            {dataJS[agreementFields.FUEL_TYPE] !==
              FuelType.RENEWABLE_ELECTRICITY && (
              <Col>
                <b>{t('info.fuelGeneration')}: </b>
                {translateBiofuelTypeClassificator(
                  dataJS[agreementFields.FUEL_GENERATION]
                )}
              </Col>
            )}
          </Row>

          <Row className="global-margin-bottom-10 column">
            <Col>
              <b>{t('info.naturalAmountMj')}: </b>
              {dataJS[agreementFields.AGREEMENT_TYPE] === 'ENTIRE'
                ? t('info.all')
                : dataJS[agreementFields.NATURAL_AMOUNT_MJ]}
            </Col>
            <Col>
              <b>{t('info.multiplier')}: </b>
              {dataJS[agreementFields.MULTIPLIER]}
            </Col>
            <Col>
              <b>{t('info.calculatedAmountMj')}: </b>
              {dataJS[agreementFields.AGREEMENT_TYPE] === 'ENTIRE'
                ? t('info.all')
                : dataJS[agreementFields.CALCULATED_AMOUNT_MJ]}
            </Col>
          </Row>

          <Row className="global-margin-bottom-10 column">
            {dataJS[agreementFields.GHG_CAPACITY] && (
              <Col>
                <b>
                  {t('info.ghgCapacity')}&nbsp;
                  <Tooltip title={t('info.ghgTooltip')}>
                    <Icon type="question-circle-o" />
                  </Tooltip>:{' '}
                </b>
                {dataJS[agreementFields.GHG_CAPACITY].toFixed(1)}
              </Col>
            )}
            <Col>
              <b>
                {t('info.totalTransactionCost')}&nbsp;
                <Tooltip title={t('info.transactionCostTooltip')}>
                  <Icon type="question-circle-o" />
                </Tooltip>:{' '}
              </b>
              {dataJS[agreementFields.AGREEMENT_TYPE] === 'ENTIRE'
                ? '-'
                : dataJS[agreementFields.TOTAL_TRANSACTION_COST]}
            </Col>
            <Col>
              <b>
                {t('info.unitPrice')}&nbsp;
                <Tooltip title={t('info.unitPriceTooltip')}>
                  <Icon type="question-circle-o" />
                </Tooltip>:{' '}
              </b>
              {dataJS[agreementFields.UNIT_PRICE]}
            </Col>
          </Row>
        </div>

        <h2 className="global-margin-top-42">
          {t('title_fulfillment_details')}
        </h2>

        <Row className="global-margin-bottom-10 global-margin-top-24">
          <Col span={6}>
            <b>{t('info.fulfilledPercentage')}: </b>
            {dataJS[agreementFields.AGREEMENT_TYPE] === 'ENTIRE'
              ? '-'
              : dataJS[agreementFields.FULFILLED_PERCENTAGE]}
          </Col>
          <Col span={6}>
            <b>{t('info.fulfilledNaturalAmountMj')}: </b>
            {dataJS[agreementFields.AGREEMENT_TYPE] === 'ENTIRE'
              ? t('info.all')
              : dataJS[agreementFields.FULFILLED_NATURAL_AMOUNT_MJ]}
          </Col>
          <Col span={6}>
            <b>{t('info.fulfilledCalculatedAmountMj')}: </b>
            {dataJS[agreementFields.AGREEMENT_TYPE] === 'ENTIRE'
              ? t('info.all')
              : dataJS[agreementFields.FULFILLED_CALCULATED_AMOUNT_MJ]}
          </Col>
        </Row>

        <h2 className="global-margin-top-42">{t('title_ts_details')}</h2>
        <TransportStatisticsList
          transactions={transactions.toJS()}
          isTransactionsLoading={isTransactionsLoading}
          search={fetchAgreementTransactions}
          agreement={dataJS}
          t={t}
          {...rest}
          {...transactionsState.toJS()}
        />

        <TableActions>
          <Button
            onClick={() => {
              isUserAdmin
                ? goToTradingPlatformAgreements()
                : goToAccountGOSPreTradingAgreements(this.props.registerCode);
            }}
            className="global-margin-left-10"
          >
            {t('buttonBack')}
          </Button>
        </TableActions>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  agreementData: getAgreementData(state),
  isAgreementDataLoading: getLoadingSelector(getAgreementData)(state),
  transactionsState: getAgreementTransactionList(state),
  transactions: getAgreementTransactionsEntities(state),
  isTransactionsLoading: getLoadingSelector(getAgreementTransactionList)(state),
  isUserAdmin: isAdmin(getRole(state)),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  fetchAgreement: id => {
    dispatch(actions.fetchAgreement(id));
  },
  resetAgreement: () => dispatch(agreementResetMaster()),
  fetchAgreementTransactions: (id, pager) => {
    dispatch(actions.fetchAgreementTransactions(id, pager));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(
  PreTradingAgreementView
);
