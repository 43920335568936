import React, { Component } from 'react';
import { reduxForm, Field, formValueSelector } from 'redux-form/immutable';
import { Map } from 'immutable';
import PropTypes from 'prop-types';
import PropTypesImmutable from 'react-immutable-proptypes';
import {
  DateField,
  ClassificatorField,
  CheckboxField,
  SelectField,
} from '../../atoms';
import { FormActions, TableActions } from '../../components';
import { connect } from 'react-redux';
import { Collapse, Row, Col, Button } from 'antd';
import { FORM_ACCOUNT_OVERVIEW_FILTER } from '../../constants/formKeys';
import { legalEntityFields } from '../../constants/legalEntity';
import { formAccountOverviewFilter } from '../../constants/forms/formAccountOverviewFilter';
import * as actions from '../../actions/actionCreators/accountActionCreators';
import * as productionUnitActions from '../../actions/actionCreators/productionUinitActionCreators';
import { getRole, getUserLegalEntity } from '../../reducers/userReducer';
import { getProductionUnitListEntities } from '../../reducers/legalEntityProductionUnitsReducer';
import moment from 'moment';
import _ from 'lodash';
import { FORMAT_DEFAULT } from '../../utils';
import { links } from '../../utils';
import { downloadFileByPostURL } from '../../utils/files';
import { isAdmin } from '../../utils/roles';
import gasType from '../../constants/classificators/gasType';
import { productionUnitFields } from '../../constants/productionUnit';
import { accountTableFields } from '../../constants/accountTable';

const { Panel } = Collapse;

const getInitialValues = (accountIds, legalEntity, userRole, manufacture) => {
  const isUserAdmin = isAdmin(userRole);
  let gas;
  if (
    (legalEntity &&
      legalEntity.get('manufacturer') === true &&
      legalEntity.get('hydrogenManufacturer') === true) ||
    isUserAdmin ||
    !manufacture
  ) {
    gas = null;
  } else if (legalEntity.get('manufacturer') === true) {
    gas = gasType.BIOMETHANE_GAS_TYPE;
  } else if (legalEntity.get('hydrogenManufacturer') === true) {
    gas = gasType.HYDROGEN_GAS_TYPE;
  }
  const ids = accountIds.filter(id => id !== null);
  return Map({
    [formAccountOverviewFilter.GROUP_BY_EXPIRATION]: true,
    [formAccountOverviewFilter.BALANCE_DATE]: moment().format(FORMAT_DEFAULT),
    [formAccountOverviewFilter.GAS_TYPE]: gas,
    accountIds: ids,
  });
};

export class AccountOverviewFilter extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    submit: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    fetchAccountOverview: PropTypes.func.isRequired,
    fetchLegalEntityProductionUnits: PropTypes.func.isRequired,
    legalEntity: PropTypesImmutable.map.isRequired,
    productionUnitList: PropTypesImmutable.list,
    accountIds: PropTypes.array,
    filterValues: PropTypes.object.isRequired,
    manufacture: PropTypes.bool,
    changeField: PropTypes.func,
    resetFields: PropTypes.func.isRequired,
  };

  componentDidMount = () => {
    const {
      fetchLegalEntityProductionUnits,
      legalEntity,
      fetchAccountOverview,
      accountIds,
      userRole,
      manufacture,
    } = this.props;
    fetchLegalEntityProductionUnits(legalEntity.get(legalEntityFields.ID));
    fetchAccountOverview(
      getInitialValues(accountIds, legalEntity, userRole, manufacture)
    );
  };

  componentDidUpdate = prevProps => {
    const {
      fetchAccountOverview,
      accountIds,
      reset,
      legalEntity,
      userRole,
      manufacture,
    } = this.props;
    if (!_.isEqual(accountIds, prevProps.accountIds)) {
      reset();
      fetchAccountOverview(
        getInitialValues(accountIds, legalEntity, userRole, manufacture)
      );
    }
  };

  resetForm = () => {
    const { reset, clearFields } = this.props;
    clearFields();
    reset();
  };

  handleExportTransactions = () => {
    const { filterValues } = this.props;

    downloadFileByPostURL(
      links.goSearchExportCsv,
      filterValues,
      'account-overview.csv'
    );
  };

  render() {
    const {
      t,
      handleSubmit,
      reset,
      productionUnitList,
      filterValues,
      manufacture,
      changeField,
      resetFields,
    } = this.props;

    return (
      <React.Fragment>
        <Collapse defaultActiveKey={['filters']}>
          <Panel key="filters" header={t('filter.title')}>
            <form onSubmit={handleSubmit}>
              <Row gutter={32}>
                <Col span={16}>
                  <Row gutter={32}>
                    <Col span={12}>
                      <Field
                        className="date-field"
                        name={formAccountOverviewFilter.VALID_UNTIL}
                        component={DateField}
                        label={t('overview.filter.validUntil')}
                        hasFeedback={false}
                      />
                    </Col>
                    <Col span={12}>
                      <Field
                        label={t('overview.filter.gas')}
                        name={formAccountOverviewFilter.GAS_TYPE}
                        component={ClassificatorField}
                        type={ClassificatorField.types.gasType}
                        hasFeedback={false}
                        onChange={() => {
                          resetFields();
                          reset();
                        }}
                      />
                    </Col>
                  </Row>
                  {filterValues.gasType === gasType.BIOMETHANE_GAS_TYPE && (
                    <Row gutter={32}>
                      <Col span={12}>
                        <Field
                          label={t('overview.filter.fuelType')}
                          name={formAccountOverviewFilter.FUEL_TYPE}
                          component={ClassificatorField}
                          type={ClassificatorField.types.fuelType}
                          hasFeedback={false}
                        />
                      </Col>
                      <Col span={12}>
                        <Field
                          label={t('overview.filter.cleaningTechnologyType')}
                          name={
                            formAccountOverviewFilter.CLEANING_TECHNOLOGY_TYPE
                          }
                          component={ClassificatorField}
                          type={ClassificatorField.types.cleaningTechnologyType}
                          hasFeedback={false}
                        />
                      </Col>
                    </Row>
                  )}
                  {filterValues.gasType === gasType.HYDROGEN_GAS_TYPE && (
                    <Row gutter={32}>
                      <Col span={12}>
                        <Field
                          label={t('overview.filter.hydrogenType')}
                          name={formAccountOverviewFilter.HYDROGEN_FUEL_TYPE}
                          component={ClassificatorField}
                          type={ClassificatorField.types.hydrogenFuelType}
                          hasFeedback={false}
                        />
                      </Col>
                      <Col span={12}>
                        <Field
                          label={t('overview.filter.hydrogenTechnologyType')}
                          name={
                            formAccountOverviewFilter.HYDROGEN_TECHNOLOGY_TYPE
                          }
                          component={ClassificatorField}
                          type={ClassificatorField.types.hydrogenTechnologyType}
                          hasFeedback={false}
                        />
                      </Col>
                    </Row>
                  )}
                  {manufacture && (
                    <Row gutter={32}>
                      <Col span={12}>
                        <Field
                          label={t('overview.filter.productionUnit')}
                          name={formAccountOverviewFilter.PRODUCTION_UNIT_ID}
                          component={SelectField}
                          options={productionUnitList.toJS()}
                          valueKey={productionUnitFields.ID}
                          labelKey={productionUnitFields.NAME}
                          hasFeedback={false}
                        />
                      </Col>
                    </Row>
                  )}
                </Col>
                <Col span={8}>
                  <span className="page-AccountOverviewPage__groupByLabel">
                    {t('overview.filter.groupBy')}
                  </span>
                  <div className="page-AccountOverviewPage__groupBy">
                    {filterValues.gasType === gasType.BIOMETHANE_GAS_TYPE && (
                      <Field
                        name={formAccountOverviewFilter.GROUP_BY_TECHNOLOGY}
                        component={CheckboxField}
                        hasFeedback={false}
                        checkboxText={t('overview.filter.groupByTechnology')}
                        onChange={e => {
                          changeField(
                            accountTableFields.TECHNOLOGY,
                            e.target.checked
                          );
                        }}
                      />
                    )}
                    {filterValues.gasType === gasType.BIOMETHANE_GAS_TYPE && (
                      <Field
                        name={formAccountOverviewFilter.GROUP_BY_FUEL_TYPE}
                        component={CheckboxField}
                        hasFeedback={false}
                        checkboxText={t('overview.filter.groupByFuelType')}
                        onChange={e => {
                          changeField(
                            accountTableFields.FUEL_TYPE,
                            e.target.checked
                          );
                        }}
                      />
                    )}
                    {filterValues.gasType === gasType.HYDROGEN_GAS_TYPE && (
                      <Field
                        name={
                          formAccountOverviewFilter.GROUP_BY_HYDROGEN_TECHNOLOGY
                        }
                        component={CheckboxField}
                        hasFeedback={false}
                        checkboxText={t(
                          'overview.filter.groupByHydrogenTechnology'
                        )}
                        onChange={e => {
                          changeField(
                            accountTableFields.HYDROGEN_TECHNOLOGY,
                            e.target.checked
                          );
                        }}
                      />
                    )}
                    {filterValues.gasType === gasType.HYDROGEN_GAS_TYPE && (
                      <Field
                        name={
                          formAccountOverviewFilter.GROUP_BY_HYDROGEN_FUEL_TYPE
                        }
                        component={CheckboxField}
                        hasFeedback={false}
                        checkboxText={t(
                          'overview.filter.groupByHydrogeFuelType'
                        )}
                        onChange={e => {
                          changeField(
                            accountTableFields.HYDROGEN_FUEL_TYPE,
                            e.target.checked
                          );
                        }}
                      />
                    )}
                    <Field
                      name={formAccountOverviewFilter.GROUP_BY_EXPIRATION}
                      component={CheckboxField}
                      hasFeedback={false}
                      disabled
                      checkboxText={t('overview.filter.groupByExpiration')}
                    />
                  </div>
                </Col>
              </Row>
              <FormActions>
                <Button onClick={reset}>{t('filter.clearBtn')}</Button>
                <Button type="primary" htmlType="submit">
                  {t('filter.submitBtn')}
                </Button>
              </FormActions>
            </form>
          </Panel>
        </Collapse>
        <TableActions>
          <Button onClick={this.handleExportTransactions}>
            {t('exportCsvBtn')}
          </Button>
        </TableActions>
        {/*<b>{t('overview.info')}</b>*/}
      </React.Fragment>
    );
  }
}

const filterForm = formValueSelector(FORM_ACCOUNT_OVERVIEW_FILTER);

const mapStateToProps = (state, ownProps) => {
  const legalEntity = getUserLegalEntity(state);
  const userRole = getRole(state);
  return {
    userRole: userRole,
    legalEntity: legalEntity,
    productionUnitList: getProductionUnitListEntities(state),
    initialValues: getInitialValues(
      ownProps.accountIds,
      legalEntity,
      userRole,
      ownProps.manufacture
    ),
    filterValues: filterForm(
      state,
      ...Object.values(formAccountOverviewFilter)
    ),
  };
};

const onSubmit = (values, dispatch, ownProps) => {
  ownProps.renderFields();
  const updatedValues = values.toJS();
  updatedValues.accountIds = ownProps.accountIds;
  dispatch(actions.fetchAccountOverview(updatedValues));
};

const mapDispatchToProps = {
  fetchLegalEntityProductionUnits:
    productionUnitActions.fetchLegalEntityProductionUnits,
  fetchAccountOverview: actions.fetchAccountOverview,
};

export default connect(mapStateToProps, mapDispatchToProps)(
  reduxForm({
    form: FORM_ACCOUNT_OVERVIEW_FILTER,
    onSubmit,
  })(AccountOverviewFilter)
);
