import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Collapse, Form, Row } from 'antd';
import { Field, getFormValues, reduxForm, change } from 'redux-form/immutable';
import { ClassificatorField, NumberField, YearSelect } from '../../atoms';
import AutoCompleteAccountsRegCodeField from '../../components/AutoCompleteAccountsField/AutoCompleteAccountsRegCodeField';
import { FormActions } from '../../components';
import PropTypesImmutable from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import { downloadFileByPostURL } from '../../utils/files';
import {
  FORM_TRADING_PLATFORM_AGREEMENTS_FILTER,
  tradingPlatformAgreementsFilter,
} from '../../constants/tradingPlatformAgreementsFilter';
import * as tradingPlatformAgreementsActionCreators from '../../actions/actionCreators/tradingPlatformAgreementsActionCreators';
import { links } from '../../utils';
import { fuelType, biofuelType } from '../../constants/classificators';

const { Panel } = Collapse;

const yearSelect = field => (
  <Form.Item label={field.label}>
    <YearSelect
      {...field.input}
      onChange={value => field.input.onChange(value)}
      beforeCurrent={false}
      afterCurrent={true}
      placeholder={field.placeholder}
      yearDistance={5}
    />
  </Form.Item>
);

export const getAllowedFuelTypes = () => {
  return [
    fuelType.BIOMETHANE,
    fuelType.HYDROGEN,
    fuelType.RENEWABLE_ELECTRICITY,
    fuelType.RENEWABLE_ENERGY,
  ];
};

export const getAllowedBiofuelTypes = fuelTypeVal => {
  if (fuelTypeVal === undefined) {
    return [
      biofuelType.RFNBO_HYDROGEN,
      biofuelType.OTHER_RENEWABLE_HYDROGEN,
      biofuelType.ADVANCED,
      biofuelType.FIRST_GEN,
      biofuelType.OTHER,
    ];
  } else if (fuelTypeVal === fuelType.HYDROGEN) {
    return [biofuelType.RFNBO_HYDROGEN, biofuelType.OTHER_RENEWABLE_HYDROGEN];
  } else {
    return [biofuelType.ADVANCED, biofuelType.FIRST_GEN, biofuelType.OTHER];
  }
};

export class TradingPlatformAgreementsFilter extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    filterValues: PropTypesImmutable.map,
    dispatch: PropTypes.func.isRequired,
  };

  lastUsedFilters = {};

  handleCsvExport = () => {
    downloadFileByPostURL(
      links.tsAllAgreementsCsv,
      this.lastUsedFilters,
      'Eelkokkulepped.csv'
    );
  };

  handleFuelTypeChange = value => {
    this.props.dispatch(
      change(
        FORM_TRADING_PLATFORM_AGREEMENTS_FILTER,
        tradingPlatformAgreementsFilter.FUEL_GENERATION,
        null
      )
    );
  };

  render() {
    const { t, reset, handleSubmit, filterValues } = this.props;
    return (
      <React.Fragment>
        <Collapse>
          <Panel
            key="agreements-filter"
            header={t('agreements.filter.panelTitle')}
          >
            <form onSubmit={handleSubmit(onSubmit.bind(this))}>
              <Row gutter={24}>
                <Col span={8}>
                  <Field
                    name={tradingPlatformAgreementsFilter.STATUS}
                    label={t('agreements.filter.status')}
                    component={ClassificatorField}
                    type={ClassificatorField.types.tsStatus}
                    hasFeedback={false}
                  />
                </Col>
                <Col span={8}>
                  <Field
                    name={tradingPlatformAgreementsFilter.SELLER_REGISTER_CODE}
                    label={t('agreements.filter.seller')}
                    component={AutoCompleteAccountsRegCodeField}
                    hasFeedback={false}
                  />
                </Col>
                <Col span={8}>
                  <Field
                    name={tradingPlatformAgreementsFilter.BUYER_REGISTER_CODE}
                    label={t('agreements.filter.buyer')}
                    component={AutoCompleteAccountsRegCodeField}
                    hasFeedback={false}
                  />
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={8}>
                  <Field
                    name={tradingPlatformAgreementsFilter.FUEL_TYPE}
                    label={t('agreements.filter.fuelType')}
                    component={ClassificatorField}
                    type={ClassificatorField.types.tsFuelType}
                    hasFeedback={false}
                    allowed={[
                      fuelType.BIOMETHANE,
                      fuelType.HYDROGEN,
                      fuelType.RENEWABLE_ELECTRICITY,
                      fuelType.RENEWABLE_ENERGY,
                    ]}
                    onChange={this.handleFuelTypeChange}
                  />
                </Col>
                {filterValues?.toJS().fuelType !==
                  fuelType.RENEWABLE_ELECTRICITY && (
                  <Col span={8}>
                    <Field
                      name={tradingPlatformAgreementsFilter.FUEL_GENERATION}
                      label={t('agreements.filter.fuelGeneration')}
                      component={ClassificatorField}
                      type={ClassificatorField.types.biofuelType}
                      hasFeedback={false}
                      allowed={getAllowedBiofuelTypes(
                        filterValues?.toJS().fuelType
                      )}
                    />
                  </Col>
                )}
                <Col span={8}>
                  <Field
                    name={tradingPlatformAgreementsFilter.AGREEMENT_TYPE}
                    label={t('agreements.filter.agreementType')}
                    component={ClassificatorField}
                    type={ClassificatorField.types.agreementType}
                    hasFeedback={false}
                  />
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={8}>
                  <Field
                    name={
                      tradingPlatformAgreementsFilter.FINAL_CONSUMPTION_YEAR
                    }
                    label={t('agreements.filter.finalConsumptionYear')}
                    placeholder={t('agreements.filter.selectYear')}
                    component={yearSelect}
                    hasFeedback={false}
                  />
                </Col>
                <Col span={8}>
                  <Field
                    name={tradingPlatformAgreementsFilter.ID}
                    label={t('agreements.filter.agreementId')}
                    component={NumberField}
                    hasFeedback={false}
                    precision={0}
                  />
                </Col>
              </Row>
              {filterActions.bind(this)(t, reset)}
            </form>
          </Panel>
        </Collapse>
        <FormActions>
          <Button onClick={() => this.handleCsvExport()}>
            {t('agreements.filter.csvGeneralBtn')}
          </Button>
        </FormActions>
      </React.Fragment>
    );
  }
}

function filterActions(t, reset) {
  return (
    <FormActions>
      <Button
        onClick={() => {
          reset();
          this.lastUsedFilters = {};
        }}
      >
        {t('transactions.filter.clearBtn')}
      </Button>
      <Button
        onClick={() => (this.lastUsedFilters = this.props.filterValues || {})}
        type="primary"
        htmlType="submit"
      >
        {t('transactions.filter.submitBtn')}
      </Button>
    </FormActions>
  );
}

function onSubmit(values, dispatch) {
  return dispatch(
    tradingPlatformAgreementsActionCreators.searchTradingPlatformAgreements(
      values
    )
  );
}

const mapStateToProps = state => ({
  filterValues: getFormValues(FORM_TRADING_PLATFORM_AGREEMENTS_FILTER)(state),
});

export default connect(mapStateToProps)(
  reduxForm({
    form: FORM_TRADING_PLATFORM_AGREEMENTS_FILTER,
  })(TradingPlatformAgreementsFilter)
);
