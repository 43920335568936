import React, { Component } from 'react';
import PropTypes from 'prop-types';
import PropTypesImmutable from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import * as selectors from '../../reducers/tsAuctionReducer';
import {
  getSelectedAuctionRows,
  getSelectedAuctionRowsData,
} from '../../reducers/tsAuctionReducer';
import { getLoadingSelector } from '../../utils/asyncHelpers';
import {
  Button,
  Checkbox,
  DatePicker,
  Icon,
  InputNumber,
  message,
  Modal,
  Popconfirm,
  Spin,
  Table,
  Tooltip,
} from 'antd';
import { tradingAccountOverviewTableFields } from '../../constants/TradingAccountOverviewTable';
import {
  translateFeedstockClassificator,
  translateFuelTypeClassificator,
  translateTsFuelGenerationClassificator,
} from '../../utils/translateHelpers';
import {
  TYPE_B,
  TYPE_E,
  TYPE_H,
  TYPE_KHG,
  TYPE_S,
} from '../../constants/accountGOS';
import { TableActions, TableRowActions } from '../../components';
import { renderModalDetails } from '../../components/TradingAccount/OverviewModalDetails';
import { goBack } from '../../utils/gotoLink';
import _ from 'lodash';
import './TradingAccountAuction.scss';
import {
  getTransportStatisticsAdditionalInfoModal,
  hideTransportStatisticsAdditionalInfoModal,
  showTransportStatisticsAdditionalInfoModal,
} from '../../actions/actionCreators/modalsActionCreators';
import { TransportStatisticsInfoModal } from '../TransportStatisticsInfoModal/TransportStatisticsInfoModal';
import { fuelType } from '../../constants/classificators';
import moment from 'moment';
import { tradingAccountAuctionAction } from '../../actions/actionCreators/tsAuctionActionCreators';
import { disabledDate, disabledTime } from './AuctionDatePickerLogic';

class TradingAccountAuction extends Component {
  static propTypes = {
    isLoading: PropTypes.bool.isRequired,
    t: PropTypes.func.isRequired,
    groupedGOSCertificates: PropTypesImmutable.list.isRequired,
    type: PropTypes.string.isRequired,

    isInfoModalVisible: PropTypes.bool.isRequired,
    showInfoModal: PropTypes.func.isRequired,
    hideInfoModal: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      modalDetails: {},
      modalDetailsVisible: false,
      selectedRow: null,
      dataList: props.groupedGOSCertificates,
      singleAuction: false,
      isB: props.type === TYPE_B,
      isH: props.type === TYPE_H,
      isE: props.type === TYPE_E,
      isS: props.type === TYPE_S,
      isKHG: props.type === TYPE_KHG,
    };
  }

  componentDidMount() {
    this.setState({ dataList: this.updateSummary(this.state.dataList) });
  }

  getAuctionInputRows = (validation = false) => {
    const { isKHG } = this.state;

    const inputRows = [
      tradingAccountOverviewTableFields.MIN_UNIT_PRICE,
      tradingAccountOverviewTableFields.MIN_BID_STEP,
      tradingAccountOverviewTableFields.END_DATE,
    ];

    if (validation) {
      inputRows.push(
        isKHG
          ? tradingAccountOverviewTableFields.GHG_TRANSFER_AMOUNT
          : tradingAccountOverviewTableFields.NATURAL_ENERGY_TRANSFER_AMOUNT
      );
    } else {
      inputRows.push(tradingAccountOverviewTableFields.ADDITIONAL_INFO);
    }

    return inputRows;
  };

  handleCheckboxChange = value => {
    this.setState({ singleAuction: value });
    if (value) this.clearInputRows();
  };

  clearInputRows = () => {
    const { dataList } = this.state;
    const keys = this.getAuctionInputRows();

    const newDataList = dataList.map(obj => {
      keys.forEach(key => {
        obj[key] = null;
      });
      return obj;
    });

    this.updateSummary(newDataList);
    this.setState({ dataList: newDataList });
  };

  handleChange = (value, record, key) => {
    const { singleAuction } = this.state;
    const newDataList = [...this.state.dataList];
    const recordIndex = newDataList.findIndex(item => item.id === record.id);

    if (
      key === tradingAccountOverviewTableFields.END_DATE &&
      this.isDisabledTimeValue(value, newDataList[recordIndex].expirationDate)
    ) {
      return;
    }

    if (singleAuction && this.getAuctionInputRows().includes(key)) {
      newDataList.forEach(row => {
        row[key] = value;
      });
    } else {
      newDataList[recordIndex][key] = value;
    }

    this.setState({
      dataList: this.updateSummary(newDataList),
    });
  };

  isDisabledTimeValue = (value, expirationDate) => {
    if (value === null) return;
    const selectedHour = value.hours();
    const disabledValues = disabledTime(expirationDate, value);

    if (disabledValues.disabledHours().includes(selectedHour))
      value.hours(this.lowestUnusedNumber(disabledValues.disabledHours()));
    if (disabledValues.disabledMinutes(selectedHour).includes(value.minutes()))
      value.minutes(this.lowestUnusedNumber(disabledValues.disabledMinutes()));
    return false;
  };

  lowestUnusedNumber = (sequence, startingFrom = 0) => {
    const arr = sequence.slice(0);
    arr.sort((a, b) => a - b);

    return arr.reduce((lowest, num, i) => {
      const seqIndex = i + startingFrom;
      return num !== seqIndex && seqIndex < lowest ? seqIndex : lowest;
    }, arr.length + startingFrom);
  };

  updateSummary = data => {
    const { isKHG } = this.state;

    const naturalAmount =
      tradingAccountOverviewTableFields.NATURAL_ENERGY_TRANSFER_AMOUNT;
    const ghgAmount = tradingAccountOverviewTableFields.GHG_TRANSFER_AMOUNT;

    const calcMultiplier =
      tradingAccountOverviewTableFields.CALCULATED_MULTIPLIER;
    const minUnitPrice = tradingAccountOverviewTableFields.MIN_UNIT_PRICE;

    const calculatedAmount =
      tradingAccountOverviewTableFields.CALCULATED_ENERGY_TRANSFER_AMOUNT;
    const transactionCost = tradingAccountOverviewTableFields.TRANSACTION_COST;

    let summary = this.getOrCreateSummary(data);
    summary[naturalAmount] = this.sum(data, naturalAmount);
    summary[ghgAmount] = this.sum(data, ghgAmount);
    summary[calculatedAmount] = this.multiplyFieldsSum(
      data,
      [],
      naturalAmount,
      calcMultiplier
    ).toFixed(1);
    summary[transactionCost] = isKHG
      ? this.multiplyFieldsSum(data, [-1], ghgAmount, minUnitPrice).toFixed(3)
      : this.multiplyFieldsSum(
          data,
          [0.001],
          naturalAmount,
          calcMultiplier,
          minUnitPrice
        ).toFixed(3);

    return data;
  };

  getOrCreateSummary(data) {
    let summary = data.find(row => row.isSummary);
    if (!summary) {
      summary = this.createSummary();
      data.push(summary);
    }
    return summary;
  }

  createSummary() {
    const { t } = this.props;

    return {
      isSummary: true,
      id: 0,
      biofuelType: t('table.total') + ':',
    };
  }

  sum(data, field) {
    const totalSum = data
      .filter(row => !row.isSummary)
      .reduce((sum, current) => {
        const fieldValue = _.get(current, field);
        const numericValue =
          fieldValue === undefined ? 0 : parseFloat(fieldValue);
        return isNaN(numericValue) ? sum : numericValue + sum;
      }, 0);

    return totalSum.toFixed(1);
  }

  multiplyFieldsSum(data, values, ...fields) {
    return data.filter(row => !row.isSummary).reduce((product, current) => {
      const fieldValues = fields.map(field => {
        const fieldValue = _.get(current, field);
        const numericValue =
          fieldValue === undefined ? 0 : parseFloat(fieldValue);
        return isNaN(numericValue) ? 0 : numericValue;
      });

      const fieldValuesProduct = this.multiplyValues(
        ...fieldValues.concat(values)
      );

      return product + fieldValuesProduct;
    }, 0);
  }

  onRemove = id => {
    const { t } = this.props;
    const data = this.state.dataList.slice();

    if (data.length <= 2) {
      message.warning(t('warningOne'));
    } else {
      const updatedData = data.filter(
        row => row[tradingAccountOverviewTableFields.ID] !== id
      );
      this.setState({ dataList: updatedData });
      this.updateSummary(updatedData);
    }
  };

  handleShowDetailsModal = details => {
    this.setState({ modalDetails: details });
    this.setState({ modalDetailsVisible: true });
  };

  hideDetailModal = () => {
    this.setState({ modalDetailsVisible: false });
    this.setState({ modalDetails: {} });
  };

  getColumns = () => {
    const { t, showInfoModal } = this.props;
    const { isB, isH, isS, isE, isKHG, singleAuction } = this.state;

    const columns = [];
    const isDisabled = rowIndex => singleAuction && rowIndex !== 0;
    const khg = isKHG ? 'Khg' : '';

    if (isB) {
      columns.push(
        {
          title: t('table.fuelType'),
          dataIndex: tradingAccountOverviewTableFields.FUEL_TYPE,
          render: translateFuelTypeClassificator,
        },
        {
          title: t('table.feedstock'),
          dataIndex: tradingAccountOverviewTableFields.FEEDSTOCK,
          render: translateFeedstockClassificator,
        }
      );
    }

    if (isE) {
      columns.push({
        title: t('table.consumptionTime'),
        dataIndex: tradingAccountOverviewTableFields.CONSUMPTION_TIME,
        render: (value, record) => {
          return (
            <>
              {this.renderNonSummaryRow(
                record,
                record.consumptionMonth + '.' + record.consumptionYear,
                record.biofuelType
              )}
            </>
          );
        },
      });
    }

    if (isKHG) {
      columns.push({
        title: t('details.ghgAmount'),
        dataIndex: tradingAccountOverviewTableFields.GHG_TRANSFER_AMOUNT,
        render: (value, record) => {
          const dataIndex =
            tradingAccountOverviewTableFields.GHG_TRANSFER_AMOUNT;
          const maxAmount =
            record[tradingAccountOverviewTableFields.GHG_AMOUNT];
          return this.renderNonSummaryRow(
            record,
            <InputNumber
              min={maxAmount}
              step={0.1}
              precision={1}
              max={-0.1}
              value={value}
              style={{ width: '100%' }}
              onChange={amount => this.handleChange(amount, record, dataIndex)}
            />,
            value ? value : 0
          );
        },
      });
    }

    if (isB || isS) {
      columns.push({
        title: t('table.fuelGeneration'),
        dataIndex: tradingAccountOverviewTableFields.BIOFUEL_TYPE,
        render: (value, record) => {
          return this.renderNonSummaryRow(
            record,
            translateTsFuelGenerationClassificator(value),
            value
          );
        },
      });
    }

    if (isH) {
      columns.push({
        title: t('table.hydrogenType'),
        dataIndex: tradingAccountOverviewTableFields.BIOFUEL_TYPE,
        render: (value, record) => {
          return this.renderNonSummaryRow(
            record,
            translateTsFuelGenerationClassificator(value),
            value
          );
        },
      });
    }

    if (isB || isE || isS || isH) {
      columns.push(
        {
          title: t('table.naturalAmountMj'),
          dataIndex:
            tradingAccountOverviewTableFields.NATURAL_ENERGY_TRANSFER_AMOUNT,
          render: (value, record) => {
            const dataIndex =
              tradingAccountOverviewTableFields.NATURAL_ENERGY_TRANSFER_AMOUNT;
            const maxAmount =
              record[tradingAccountOverviewTableFields.NATURAL_ENERGY_AMOUNT];
            return this.renderNonSummaryRow(
              record,
              <InputNumber
                min={0.1}
                step={0.1}
                precision={1}
                max={maxAmount}
                value={value}
                style={{ width: '100%' }}
                onChange={value => this.handleChange(value, record, dataIndex)}
              />,
              value ? value : 0
            );
          },
        },
        {
          title: t('table.multiplier'),
          dataIndex: tradingAccountOverviewTableFields.CALCULATED_MULTIPLIER,
        },
        {
          title: t('table.accountedAmountMj'),
          dataIndex:
            tradingAccountOverviewTableFields.CALCULATED_ENERGY_TRANSFER_AMOUNT,
          render: (value, record) => {
            const naturalAmountMj =
              record[
                tradingAccountOverviewTableFields.NATURAL_ENERGY_TRANSFER_AMOUNT
              ];
            const multiplier =
              record[tradingAccountOverviewTableFields.CALCULATED_MULTIPLIER];

            return (
              <>
                {this.handleRowCalculation(
                  record,
                  value,
                  1,
                  naturalAmountMj,
                  multiplier
                )}
              </>
            );
          },
        },
        {
          title: t('table.ghgCapacity'),
          dataIndex: tradingAccountOverviewTableFields.GHG_CAPACITY,
        }
      );
    }

    columns.push(
      {
        title: this.renderTitleWithTooltip(
          t('table.minUnitPrice' + khg),
          t('table.minUnitPriceTooltip')
        ),
        dataIndex: tradingAccountOverviewTableFields.MIN_UNIT_PRICE,
        render: (value, record, index) => {
          const dataIndex = tradingAccountOverviewTableFields.MIN_UNIT_PRICE;
          return this.renderNonSummaryRow(
            record,
            <InputNumber
              disabled={isDisabled(index)}
              min={0.001}
              step={0.001}
              precision={3}
              value={value}
              style={{ width: '100%' }}
              onChange={price => this.handleChange(price, record, dataIndex)}
            />
          );
        },
      },
      {
        title: this.renderTitleWithTooltip(
          t('table.transactionCost'),
          t('table.transactionCostTooltip')
        ),
        dataIndex: tradingAccountOverviewTableFields.TRANSACTION_COST,
        render: (value, record) => {
          return <>{this.calculateTransactionCost(record, value, isKHG)}</>;
        },
      },
      {
        title: this.renderTitleWithTooltip(
          t('table.minBidStep' + khg),
          t('table.minBidStepTooltip')
        ),
        dataIndex: tradingAccountOverviewTableFields.MIN_BID_STEP,
        render: (value, record, index) => {
          const dataIndex = tradingAccountOverviewTableFields.MIN_BID_STEP;
          return this.renderNonSummaryRow(
            record,
            <InputNumber
              disabled={isDisabled(index)}
              min={0.001}
              step={0.001}
              precision={3}
              value={value}
              style={{ width: '100%' }}
              onChange={step => this.handleChange(step, record, dataIndex)}
            />
          );
        },
      },
      {
        title: this.renderTitleWithTooltip(
          t('table.endDate'),
          t('table.endDateTooltip')
        ),
        dataIndex: tradingAccountOverviewTableFields.END_DATE,
        render: (value, record, index) => {
          const dataIndex = tradingAccountOverviewTableFields.END_DATE;
          return this.renderNonSummaryRow(
            record,
            <DatePicker
              disabled={isDisabled(index)}
              showTime={{
                format: 'HH:mm',
                defaultValue: moment('10:00:00', 'HH:mm:ss'),
              }}
              format="YYYY-MM-DD HH:mm"
              disabledDate={current =>
                disabledDate(current, record.expirationDate)
              }
              disabledTime={current =>
                disabledTime(record.expirationDate, current)
              }
              value={record[dataIndex] ?? value}
              onChange={date => this.handleChange(date, record, dataIndex)}
              className="custom-date-picker"
              style={{ width: '100%' }}
            />
          );
        },
      },
      {
        title: this.renderTitleWithTooltip(
          t('table.additionalInfo'),
          t('table.additionalInfoTooltip')
        ),
        dataIndex: 'additionalInfo',
        render: (value, record, index) => {
          return this.renderNonSummaryRow(
            record,
            <TableRowActions>
              <Button
                class="test"
                disabled={isDisabled(index)}
                shape="circle"
                icon="edit"
                size="small"
                type="primary"
                style={{ marginLeft: '8px' }}
                title={t('table.additionalInfo')}
                onClick={() =>
                  this.setState({ selectedRow: record }, () => showInfoModal())
                }
              />
            </TableRowActions>
          );
        },
      },
      {
        title: t('table.actions'),
        width: 100,
        dataIndex: tradingAccountOverviewTableFields.ID,
        render: (value, record) => {
          return this.renderNonSummaryRow(
            record,
            <TableRowActions>
              <Button
                shape="circle"
                icon="delete"
                size="small"
                type="primary"
                title={t('table.deleteBtn')}
                onClick={() => this.onRemove(value)}
              />
              <Button
                shape="circle"
                icon="select"
                size="small"
                style={{ marginLeft: '8px' }}
                type="primary"
                title={t('table.viewBtn')}
                onClick={() => this.handleShowDetailsModal(record)}
              />
            </TableRowActions>
          );
        },
      }
    );

    return columns;
  };

  renderNonSummaryRow = (row, field, defaultField = null) => {
    return row.isSummary ? defaultField : field;
  };

  handleRowCalculation = (row, value, decimal, ...values) => {
    return row.isSummary
      ? value
      : this.multiplyValues(...values).toFixed(decimal);
  };

  multiplyValues = (...values) => {
    const sum = values.reduce(
      (accumulator, currentValue) => accumulator * currentValue,
      1
    );
    return sum ? sum : 0;
  };

  calculateTransactionCost = (record, value, isKHG) => {
    const fuelAmount = isKHG
      ? record[tradingAccountOverviewTableFields.GHG_TRANSFER_AMOUNT]
      : record[
          tradingAccountOverviewTableFields.NATURAL_ENERGY_TRANSFER_AMOUNT
        ];
    const multiplier = isKHG
      ? 1
      : record[tradingAccountOverviewTableFields.CALCULATED_MULTIPLIER];
    const minUnitPrice =
      record[tradingAccountOverviewTableFields.MIN_UNIT_PRICE];

    return this.handleRowCalculation(
      record,
      value,
      3,
      fuelAmount,
      multiplier,
      minUnitPrice,
      isKHG ? -1 : 0.001
    );
  };

  renderTitleWithTooltip = (title, tooltipTitle) => {
    return (
      <span>
        {title}&nbsp;
        <Tooltip title={tooltipTitle}>
          <Icon type="question-circle-o" />
        </Tooltip>
      </span>
    );
  };

  fuelTypeHandler = type => {
    const fuelTypeMap = {
      B: fuelType.BIOMETHANE,
      H: fuelType.HYDROGEN,
      E: fuelType.RENEWABLE_ELECTRICITY,
      S: fuelType.RENEWABLE_ENERGY,
      KHG: fuelType.KHG,
    };

    return fuelTypeMap[type];
  };

  handlePerformClick = async () => {
    const { t, type, performAction } = this.props;
    const { dataList, singleAuction, isKHG } = this.state;
    const data = dataList.filter(row => !row.isSummary);

    const isValid = this.areRequiredFieldsValid(data);
    if (!isValid) return message.error(t('errorMissingValues'));

    const result = await performAction({
      rows: data.map(row => {
        return {
          groupedId: row.groupedId,
          naturalAmountMj: row.naturalEnergyTransferAmount ?? null,
          accountedAmountMj: isKHG
            ? null
            : this.handleRowCalculation(
                row,
                null,
                1,
                row.naturalEnergyTransferAmount,
                row.calculatedMultiplier
              ),
          ghgAmount: row.ghgTransferAmount ?? null,
          minBidStep: row.minBidStep,
          minUnitPrice: row.minUnitPrice,
          endDate: row.endDate.format('YYYY-MM-DDTHH:mm:ss'),
          additionalInfo: row.additionalInfo,
        };
      }),
      singleAuction,
      fuelType: this.fuelTypeHandler(type),
    });

    if (result) {
      message.success(t('successToAuctionPerform'));
      goBack();
    }
  };

  areRequiredFieldsValid = data => {
    const { isKHG } = this.state;
    const fieldsToValidate = this.getAuctionInputRows(true);

    return data.every(item => {
      return fieldsToValidate.every(field => {
        return (
          item[field] !== null &&
          item[field] !== undefined &&
          (isKHG ? item.ghgTransferAmount < 0 : true)
        );
      });
    });
  };

  getRowClassName = (rec, idx, dataList) => {
    if (idx === dataList.length - 1) {
      return 'summary-row';
    } else {
      return '';
    }
  };

  render() {
    const {
      isLoading,
      t,
      groupedGOSCertificates,
      isInfoModalVisible,
      hideInfoModal,
    } = this.props;
    const {
      singleAuction,
      dataList,
      modalDetails,
      modalDetailsVisible,
      selectedRow,
    } = this.state;

    if (!isLoading && !groupedGOSCertificates.length) {
      return t('emptyAuction');
    }

    return (
      <Spin spinning={isLoading}>
        {isInfoModalVisible && (
          <TransportStatisticsInfoModal
            t={t}
            selectedRow={selectedRow}
            dataIndex={tradingAccountOverviewTableFields.ADDITIONAL_INFO}
            setAdditionalInfo={this.handleChange}
            hideModal={hideInfoModal}
          />
        )}

        <Modal
          title={t('detailsTitle')}
          visible={modalDetailsVisible}
          footer={false}
          width="400px"
          onCancel={this.hideDetailModal}
        >
          {renderModalDetails(t, modalDetails)}
        </Modal>

        <h2>{t('titleSendToAuctionTable')}</h2>
        <Checkbox
          onChange={event => this.handleCheckboxChange(event.target.checked)}
          checked={singleAuction}
        >
          {this.renderTitleWithTooltip(
            t('singleAuctionCheckbox'),
            t('singleAuctionCheckboxTooltip')
          )}
        </Checkbox>
        <Table
          scroll={{ x: 'auto' }}
          rowKey={tradingAccountOverviewTableFields.ID}
          dataSource={dataList}
          columns={this.getColumns()}
          pagination={false}
          rowClassName={(rec, idx) => this.getRowClassName(rec, idx, dataList)}
        />
        <TableActions>
          <Button onClick={goBack}>{t('buttonBack')}</Button>
          <Popconfirm
            title={t('popconfirmTitleAuction')}
            onConfirm={this.handlePerformClick}
            okText={t('popconfirmButtonYes')}
            cancelText={t('popconfirmButtonNo')}
          >
            <Button type="primary" className="global-margin-left-10">
              {t('buttonPerformToAuction')}
            </Button>
          </Popconfirm>
        </TableActions>
      </Spin>
    );
  }
}

const getLoading = getLoadingSelector(
  getSelectedAuctionRows,
  selectors.getToAuction
);

const mapStateToProps = state => ({
  isLoading: getLoading(state),
  groupedGOSCertificates: getSelectedAuctionRowsData(state),
  isInfoModalVisible: getTransportStatisticsAdditionalInfoModal(state),
});

const mapDispatchToProps = dispatch => ({
  performAction: data => dispatch(tradingAccountAuctionAction(data)),
  showInfoModal: () => {
    dispatch(showTransportStatisticsAdditionalInfoModal());
  },
  hideInfoModal: () => {
    dispatch(hideTransportStatisticsAdditionalInfoModal());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(
  TradingAccountAuction
);
